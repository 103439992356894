.standard-title-block{
    margin-bottom: 20px;
    margin-top: 60px;
    @media (max-width: 768px){
        margin-top: 25px;
    }
    .titleblock{
        .parent{
            color: $red;
            font-size: 13px;
            line-height: 21px;
            font-weight: 500;
            padding-bottom: 15px;
            text-transform: uppercase;
            @media (max-width: 992px){
                font-size: 11px;
                line-height: 17px;
            }
            @media (max-width: 768px){
                font-size: 10px;
                line-height: 16px;
                padding-bottom: 10px;
            }
        }
        .child{
            font-size: 50px;
            line-height: 56px;
            font-weight: 700;
            @media (max-width: 992px){
                font-size: 42px;
                line-height: 46px;
            }
            @media (max-width: 768px){
                font-size: 32px;
                line-height: 36px;
            }
        }
        &#formali-forstjora{
            @media (max-width: 768px){
                flex: 0 0 50% !important;
                max-width: 50% !important;
            }
        }
    }
}