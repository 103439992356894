.side-menu-col{
    @include media-breakpoint-down(md){
        flex: 0 0 50% !important;
        max-width: 50%;
        margin-top: 50px;
    }
    @include media-breakpoint-down(sm){
        margin-top: 10px;
    }
}
ul.side-menu{
    padding: 0;
    margin-top: 125px;
    z-index: 1;
    @include media-breakpoint-down(lg){
        margin-top: 60px;
    }
    @include media-breakpoint-down(md){
        margin-top: 30px;
    }
    @include media-breakpoint-down(sm){
        margin-top: 10px;
    }
    li.menu-items{
        list-style-type: none;
        line-height: 21px;
        margin-bottom: 15px;
        width: 100%;
        color: $white;
        position: relative;
        font-size: calc(16px + (20 - 16) * ((100vw - 375px) / (1920 - 375)));
        font-weight: 500;
        &:hover, &:active{
            font-weight: 500;
            cursor: pointer;
        }
        &:after{
            content: '';
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxMCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuNzA2MjAxIDIuMTIxMjRMNy43NzcyNyA5LjE5MjMxQzguMTY3NzkgOS41ODI4MyA4LjgwMDk2IDkuNTgyODMgOS4xOTE0OCA5LjE5MjMxQzkuNTgyMDEgOC44MDE3OCA5LjU4MjAxIDguMTY4NjIgOS4xOTE0OCA3Ljc3ODA5TDIuMTIwNDEgMC43MDcwMjZDMS43Mjk4OSAwLjMxNjUwMSAxLjA5NjcyIDAuMzE2NTAyIDAuNzA2MjAxIDAuNzA3MDI2QzAuMzE1Njc2IDEuMDk3NTUgMC4zMTU2NzYgMS43MzA3MiAwLjcwNjIwMSAyLjEyMTI0WiIgZmlsbD0iI0ZERkJGOCIvPgo8cGF0aCBkPSJNMi4xMjIyMiAxNi4xOTIyTDkuMTkzMjggOS4xMjExN0M5LjU4MzgxIDguNzMwNjQgOS41ODM4MSA4LjA5NzQ4IDkuMTkzMjggNy43MDY5NkM4LjgwMjc2IDcuMzE2NDMgOC4xNjk1OSA3LjMxNjQzIDcuNzc5MDcgNy43MDY5NkwwLjcwODAwMyAxNC43NzhDMC4zMTc0NzggMTUuMTY4NSAwLjMxNzQ3OCAxNS44MDE3IDAuNzA4MDAzIDE2LjE5MjJDMS4wOTg1MyAxNi41ODI4IDEuNzMxNjkgMTYuNTgyOCAyLjEyMjIyIDE2LjE5MjJaIiBmaWxsPSIjRkRGQkY4Ii8+Cjwvc3ZnPgo=");
            position: absolute;
            display:block;
            background-size: 20px 20px;
            width: 22px;
            height: 20px;
            top: 4px;
            z-index: 30;
            right: 38%;
            background-repeat: no-repeat;
            @media (max-width: 540px){
                right: -40%;
            }
        }
        &.grey{
            opacity: .7;
            &::after{
                content: none;
            }
        }
        ul.sub-menu{
            height: 0;
            overflow: hidden;
            display: block;
            z-index: 1;
            li{
                display: block;
            }
        }
        .line{
            display: none;
        }
        &.open{
            position: unset !important;
            .line{
                display: inline-block;
                border: 1px solid white;
                width: 25px;
                height: 2px;
                background-color: #fff;
                margin: 0 0 5px -32px;
                body.year-2021 &{
                    background-color: $dark-brown-2021;
                    border: 1px solid $dark-brown-2021;
                }
            }
            &::before{
                content: '';
                position: absolute;
                width: 25px;
                height: 2px;
                left: -32px;
                top: 50%;
            }
            &::after{
                content: none;
            }
            ul.sub-menu{
                position: absolute;
                right: -51%;
                top: 35%;
                background-color: $white;
                padding: 30px 20px 30px 34px;
                height: auto;
                width: auto;
                box-shadow: 10px 20px 30px 0px rgba(0, 0, 0, 0.25);
                min-width: 294px;
                body.year-2021 &{
                    background-color: $l3-green-2021;
                    @include media-breakpoint-down(sm){
                        right: -92%;
                    }
                }
                @media (max-width: 1200px){
                    right: -55%;
                    top: 14%;
                }
                @media (max-width: 768px){
                    min-width: 179px;
                    width: 194px;
                    right: -10px;
                    z-index: 30;
                    top: 13%;
                    padding: 20px;
                }
                @media(max-width: 540px){
                    right: -90%;
                }
                li.chapters{
                    margin-bottom: 10px;
                    a{
                        font-size: 16px !important;
                        font-weight: 500 !important;
                        color: $font !important;
                        cursor: pointer;
                        &:hover{
                            border-bottom: 2px solid $red;
                            margin-bottom: 8px;
                            font-weight: 700 !important;
                            text-decoration: none;
                            cursor: pointer;
                            body.year-2021 &{
                                border-bottom: 2px solid $blue-2021;
                            }
                        }
                    }
                }
            }
        }
    }
}