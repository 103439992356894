footer{
    height: 130px;
    width: 100vw;
    background-color: $font;
    bottom: 0;
    color: $white;
    left: 0;
    position: relative;
    z-index: 1;
    border-top: 2px solid $white;
    @media (max-width: 768px){
        height: 190px;
    }
}
.footer-container{
    @media (max-width: 768px){
        .footer-content{
            padding-left: 30px;
        }
    }
    .footer-content{
        .row{
            margin-left: 0;
        }
    }
    .footer-title{
        line-height: 30px;
        font-weight: 500;
        padding-top: 30px;
        @media (max-width: 768px){
            font-size: 14px;
        }
    }
    ul{
        padding: 15px 0 0 0;
        @media(max-width: 768px){
            display: unset !important;
        }
        li{
            line-height: 30px;
            position: relative;
            margin-right: 40px;
            list-style-type: none;
            @media (max-width: 768px){
                font-size: 14px;
                line-height: 26px;
                &::after{
                    content: none !important;
                }
            }
            &::after{
                content: '';
                position: absolute;
                width: 1px;
                height: 20px;
                right: -20px;
                top: 3px;
                background-color: $white;
            }
            &:last-of-type{
                &::after{
                    content: none;
                }
            }
            a{
                text-decoration: none;
                color: $white;
            }
        }
    }
}