.paragraph-block{
    margin-bottom: 20px;
    a{
        font-weight: 500;
        color: $gray_d1;
        border-bottom: 1px solid $gray_d1;
        z-index: 10;
        position: relative;
        &:hover{
            text-decoration: none;
        }
    }
    ul{
        li{
            line-height: 30px;
            @include media-breakpoint-down(lg){
                font-size: 1.6rem !important;
                line-height: 2.6rem !important;  
            }
        }
    }
    .crosshead{
        font-size: 22px;
        line-height: 27px;
        font-weight: 500;
        padding-bottom: 20px;
        @media(max-width: 992px){
            font-size: 21px;
            line-height: 26px;
        }
        @media (max-width: 768px){
            font-size: 20px;
            line-height: 25px;
        }
    }
}