.bottom-menu-container{
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 20;
    height: 0;
    bottom: 2px;
    transform: translateY(-50px);
    @media (max-width: 1200px){
        transform: translateY(-94px) !important;
    }
    @include media-breakpoint-down(md){
        @media screen and (orientation:landscape) {
            display: none !important;
        }
    }
    &.inner{
        padding-left: 15px;
        padding-right: 15px;
        @media (max-width: 768px){
            width: 100%;
        }
    }
    .row{
        display: flex;
        @media (min-width: 1200px){
            width: 100vw;
        }
    }
    body.year-2021 & {
        .bottom-menu{
            background-color: $dark-brown-2021 !important;
            ul{
                li{
                    a, span{
                        color: $light-font-2021;
                    }
                }
            }
        }
        .bottom-menu-btns{
            svg{
                display: none;
            }
            &.prev-page-container{
                background: $light-green-2021;
                a{
                    color: $dark-brown-2021;
                }
            }
            &.next-page-container{
                background: $dark-green-2021;
                a{
                    color: $l2-font-2021;
                }
            }
        }
    }
    .bottom-menu{
        padding-right: 0 !important;
        height: 50px;
        background-color: $gray_d1;
        max-width: 762px;
        justify-content: space-evenly;
        @media (max-width: 1200px){
            flex: 0 0 100% !important;
            order: 3;
            height: 44px;
            max-width: 790px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
        @media (max-width: 768px){

            background-color: transparent;
            height: 100%;
            position: relative;
            margin-top: 12px;
            &.sub{
                margin-top: 0;
                min-height: 44px;
                background-color: $gray_d1;
                ul{
                    justify-content: space-around;
                    li{
                        margin: 0 !important;
                    }
                }
            }
        }
        ul{
            padding: 0;
            margin-bottom: 0;
            @media (max-width: 768px){
                flex-wrap: wrap;
                width: 92vw;
            }
            li{
                list-style-type: none;
                margin-right: 26px;
                @media (max-width: 768px){
                    background-color: $gray_d1;
                    height: 38px;
                    padding: 10px 20px;
                    display: flex;
                    align-items: center;
                    margin-right: 10px !important;
                    margin-bottom: 10px;
                }
                @media (max-width: 540px){
                    padding: 10px;
                }
                a{
                    color: $white;
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 13px;
                    line-height: 14px;
                    text-decoration: none;
                    @media (max-width: 992px){
                        font-size: 12px;
                        line-height: 13px;
                    }
                    @media (max-width: 768px){
                        font-size: 11px !important;
                        line-height: 12px;
                        svg{
                            display: inline !important;
                            width: 32px;
                            height: 32px;
                        }
                        span{
                            display: none;
                        }
                    }
                    svg{
                        display: none;
                    }
                }
            }
        }
    }
    .bottom-menu-btns{
        height: 50px;
        background-color: $gray_d1;
        max-width: 174px;
        @media (max-width: 1200px){
            max-width: 210px;
            margin-bottom: 2px;
        }
        @media (max-width: 768px){
            max-width: 45% !important;
        }
        &.prev-page-container{
            background-color: $gray_l5;
            padding-right: 2px;
            @media (max-width: 1200px){
                margin-left: 15px !important;
            }
            .prev-page{
                line-height: 14px;
                text-align: center;
                a{
                    svg{
                        margin-right: 10px;
                        margin-left: -20px;
                    }
                }
            }
        }
        &.next-page-container{
            background-color: $red-80;
            @media (max-width: 1200px){
                margin-left: auto;
                margin-right: 15px;
            }
            .next-page{
                line-height: 14px;
                text-align: center;
                a{
                    svg{
                        transform: rotate(180deg);
                        margin-left: 10px;
                        
                    }
                }
            }
        }
        a{
            color: $white;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            text-decoration: none;
            @media (max-width: 992px){
                font-size: 12px;
                line-height: 13px;
            }
            @media (max-width: 768px){
                font-size: 11px;
                line-height: 12px;
            }
            svg{
                width: 6px;
                height: 11px;
                margin-bottom: 2px;
            }
        }
    }
}