.chart-block-container{
    margin-bottom: 120px;
    margin-top: 20px;
    @media (max-width: 768px){
        margin-bottom: 40px;
    }
    .chart-element{
        padding: 20px;
        background-color: $body;
        @media (max-width: 768px){
            max-height: unset !important;
        }
    }
    .title{
        font-size: 14px;
        line-height: 23px;
        color: $gray_d1;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    .subtitle{
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 24px;
        color: $gray_d1;
        font-style: italic;
        @media (max-width: 768px){
            font-size: 16px;
            line-height: 22px;
        }
    }
    .label-col{
        @media (max-width: 992px){
            order: 2;
        }
        ul{
            margin-top: 30px;
            padding-left: 30px;
            @media (max-width: 992px){
                padding-top: 0;
            }
            li{
                list-style-type: none;
                font-size: 13px;
                line-height: 14px;
                padding: 12px 0;
                margin-top: -1px;
                @media (max-width: 992px){
                    padding: 5px 0;
                }
            }
        }
    }
    .color-label{
        display: inline-block;
        height: 18px;
        width: 18px;
        border-radius: 18px;
        margin-right: 10px;
        margin-left: -30px;
        vertical-align: middle;
        &.color-red{
            background-color: $red;
        }
        &.color-red_80{
            background-color: $red-80;
        }
        &.color-secondary_red_80{
            background-color: $secondary-red-80;
        }
        &.color-gold{
            background-color: $gold;
        }
        &.color-gold_80{
            background-color: $gold-80;
        }
        &.color-blue{
            background-color: $blue;
        }
        &.color-blue_80{
            background-color: $blue-80;
        }
        &.color-brown{
            background-color: $brown;
        }
        &.color-brown_80{
            background-color: $brown-80;
        }
        &.color-maroon{
            background-color: $maroon;
        }
        &.color-green{
            background-color: $green_2020;
        }
        &.color-green_80{
            background-color: $green-2020-80;
        }
        &.color-dark_blue{
            background-color: $green;
        }
        &.color-light_blue{
            background-color: $light_blue;
        }
        &.color-yellow{
            background-color: $yellow;
        }
        &.color-beige{
            background-color: $beige;
        }
        &.color-grey_2020{
            background-color: $secondary-gray;
        }
        &.color-grey_2020_80{
            background-color: $secondary-gray-80;
        }
        &.color-light_grey{
            background-color: $gray_l4;
        }
        &.color-dark_grey{
            background-color: $gray_d3;
        }
        &.color-brons_2020{
            background-color: $brons-2020;
        }
        &.color-brons_2020_80{
            background-color: $brons-2020-80;
        }
        &.color-orange{
            background-color: $orange-2020;
        }
        &.color-orange_80{
            background-color: $orange-2020-80;
        }
        &.color-yellow_2020{
            background-color: $yellow-2020;
        }
        &.color-yellow_2020_80{
            background-color: $yellow-2020-80;
        }
        &.color-secondary_blue{
            background-color: $secondary-blue;
        }
        &.color-secondary_green{
            background-color: $secondary-green;
        }
        &.color-primary_blue_2021{
            background-color: $blue-2021;
        }
        &.color-primary_light_blue_2021{
            background-color: $light-blue-2021;
        }
        &.color-primary_pink_2021{
            background-color: $pastel-pink-2021;
        }
        &.color-primary_green_2021{
            background-color: $green-2021;
        }
        &.color-primary_yellow_2021{
            background-color: $yellow-2021;
        }
        &.color-primary_brown_2021{
            background-color: $brown-2021;
        }
        &.color-secondary_green_2021{
            background-color: $l2-green-2021;
        }
        &.color-secondary_dark_green_2021{
            background-color: $d2-green-2021;
        }
        &.color-secondary_blue_2021{
            background-color: $dark-green-2021;
        }
        &.color-secondary_brown_2021{
            background-color: $secondary-brown-2021
        }
        &.color-secondary_peach_2021{
            background-color: $peach-2021;
        }
        &.color-secondary_grey_2021{
            background-color: $secondary-grey-2021;
        }
        &.color-yellow_2022{
            background-color: $yellow-2022;
        }
        &.color-brown_2022{
            background-color: $brown-2022;
        }
        &.color-green_2022{
            background-color: $green-2022;
        }
        &.color-light_green_2022{
            background-color: $light-green-2022;
        }
        &.color-moss_green_2022{
            background-color: $moss-green-2022;
        }
        &.color-gray_2022{
            background-color: $gray-2022;
        }
        &.color-secondary-gray-2022{
            background-color: $secondary-gray-2022;
        }
        &.color-secondary_green_2022{
            background-color: $secondary-green-2022;
        }
        &.color-secondary_yellow_2022{
            background-color: $secondary-yellow-2022;
        }
        &.color-secondary_orange_2022{
            background-color: $secondary-orange-2022;
        }
        &.color-secondary_brown_2022{
            background-color: $secondary-brown-2022;
        }
        &.color-secondary_red_2022{
            background-color: $secondary-red-2022;
        }
        &.color-yellow_2023{
            background-color: $yellow-2023;
        }
        &.color-burgundy_2023{
            background-color: $burgundy-2023;
        }
        &.color-moss_green_2023{
            background-color: $moss-green-2023;
        }
        &.color-green_2023_l1{
            background-color:  $green-2023-l1;
        }
        &.color-green_2023_l2{
            background-color: $green_2023_l2;
        }
        &.color-green_2023_l3{
            background-color:  $green-2023-l3;
        }
        &.color-secondary_green_2023{
            background-color: $secondary-green-2023;
        }
        &.color-secondary_blue_2023{
            background-color: $secondary-blue-2023;
        }
        &.color-secondary_yellow_2023_d{
            background-color: $secondary-yellow-2023-d;
        }
        &.color-secondary_yellow_2023_l{
            background-color: $secondary-yellow-2023-l;
        }
        &.color-grey_2023{
            background-color: $grey-2023;
        }
        &.color-green_2023{
            background-color: $green-2023;
        }
        &.color-secondary_green_2023_d2{
            background-color: $secondary-green-2023-d2;
        }
        &.color-brown_2023{
            background-color: $brown-2023;
        }

        &.color-grey_2024 {
            background-color: $grey-2024;
        }
        &.color-grey_d1_2024 {
            background-color: $grey-d1-2024;
        }
        &.color-grey_d2_2024 {
            background-color: $grey-d2-2024;
        }
        &.color-red_2024 {
            background-color: $red-2024;
        }
        &.color-red_l1_2024 {
            background-color: $red-l1-2024;
        }
        &.color-red_l2_2024 {
            background-color: $red-l2-2024;
        }
        &.color-blue_2024 {
            background-color: $blue-2024;
        }
        &.color-blue_d1_2024 {
            background-color: $blue-d1-2024;
        }
        &.color-blue_l1_2024 {
            background-color: $blue-l1-2024;
        }
        &.color-green_2024 {
            background-color: $green-2024;
        }
        &.color-green_d1_2024 {
            background-color: $green-d1-2024;
        }
        &.color-brown_2024 {
            background-color: $brown-2024;
        }
        &.color-brown_l1_2024 {
            background-color: $brown-l1-2024;
        }
        &.color-brown_d1_2024 {
            background-color: $brown-d1-2024;
        }
        &.color-yellow_2024 {
            background-color: $yellow-2024;
        }
        &.color-yellow_d1_2024 {
            background-color: $yellow-d1-2024;
        }
        &.color-rose_2024 {
            background-color: $rose-2024;
        }
    }
    .bottom-label-container{
        margin-top: 20px;
        @media (max-width: 768px){
            flex-direction: column !important;
        }
        .inner{
            margin-right: 26px;
            @media (max-width: 768px){
                padding-bottom: 10px;
            }
            .color-label{
                margin-right: 6px;
                margin-left: 0;
            }
            span{
                font-size: 13px;
                line-height: 14px;
            }
        }
    }
    .asterisk{
        margin-top: 0px;
        padding: 0 0 10px 20px;
    }
}