.twocol-5-5{
    .col-lg-5{
        display: flex;
        align-items: flex-end;
    }
    .table-block{
        padding-top: 20px;
        padding-bottom: 20px;
        margin: 0;
        background-color: $body;
        overflow: hidden;
        .table-col{
            overflow: scroll;
        }
    }
    .chart-block-container{
        .chart-block{
            padding: 20px;
            @media (max-width: 992px){
                padding: 0;
            }
            .chart-element{
                flex: 0 0 100% !important;
                max-width: 100% !important;
            }
        }
    }
    .right-col{
        .table-block, .chart-block-container{
            @media (max-width: 992px){
                margin-top: 40px;
            }
        }
    }
}