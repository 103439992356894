.bullets-block{
    margin-top: 100px;
    @media (max-width: 992px){
        margin-top: 60px;
    }
    @media (max-width: 768px){
        margin-top: 40px;
    }
    .protocol-icon{
        img{
            width: 100%;
            max-width: 160px;
            height: auto;
            max-height: 160px;
            object-fit: cover;
        }
        .icon-title{
            font-size:15px;
            line-height: 17px;
            font-weight: 700;
            text-align: center;
            margin-top: 20px;
            margin-bottom: 30px;
        }
    }
    .paragraph{
        text-align: center;
        margin-top: 40px;
        @media (min-width: 768px) and (max-width: 992px){
            margin-top: 35px;
        }
    }   
}