body {

    /////////////////////// Minor changes for 2022 report ///////////////////////
    &.year-2022 {
        color: $gray-2022 !important;

        header{
            .top-menu-container{
                .top-menu{
                    &.color-green{
                        .menu-title{
                            color: $gray-2022;
                        }

                        .line{
                            background-color: $gray-2022;
                        }
                    }

                    .menu-title{
                        color: $yellow-2022;
                    }

                    .line{
                        background-color: $yellow-2022;
                    }
                }

                .top-ul-container{
                    a{
                        &:hover{
                            border-color: $yellow-2022;
                        }
                    }
                }
            }
        }

        
        &.page-arhomepage {
            @include media-breakpoint-down(md) {
                @media screen and (orientation:landscape) {
                    background-color: $gray-2022 !important;
                }
            }

            .ar-home-page .title{
                color: $yellow-2022;
                font-weight: 400;
            }

            .year-background{
                img{
                    max-width: 300px;
                    position: fixed;
                    bottom: 160px;
                    right: 242px;
                }
            }
        }

        &:not(.inngangur){
            .chapter-container.first .chapter-content::after {
                background-color: $gray-2022;
            }
        }

        &.inngangur:not(.arsreikningur):not(.sundurlidanir):not(.gri):not(.heimsmarkmid){
            .chapter-container.first{
                @media (min-width: 992px){
                    .right-col{
                        flex: 0 0 41.66667% !important;
                        max-width: 41.66667% !important;
                    }
                }

                @include media-breakpoint-down(md){
                    .twocol_7_5{
                        .left-col{
                            order: 2;
                        }

                        .right-col{
                            order: 1;
                        }
                    }
                }

                .article-2022-open{
                    height: 100%;

                    .container.two-column-block, .container.paragraph-block{
                        height: 100% !important;
                    }
                }

                &.inngangur{
                    .chapter-content{
                        .container.two-column-block, .container.paragraph-block{
                            height: 0;
                            overflow: hidden;
                        }

                        .two-column-block{
                            img{
                                width: 100%;
                            }
                            
                            .focus-text-block{
                                .focus-text-container{
                                    .text{
                                        padding: 0 40px 30px;
                                    }
                                }
                            }
                        }
                    }
                }               
            }
        }

        .background-container .background-image {
            background-color: $gray_d1;

            &::after {
                background-color: $gray-2022 !important;
            }

            svg.red {
                path {
                    fill: $gray-2022 !important;
                }
            }
        }

        .bottom-menu-container {
            .bottom-menu {
                background-color: $yellow-2022;
                padding-left: 0;

                @include media-breakpoint-down(lg){
                    padding-left: 15px;
                    padding-right: 15px !important;
                }

                ul li {
                    background-color: $yellow-2022;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            
            .bottom-menu-btns{
                &.prev-page-container{
                    background-color: $gray_l5;
                }

                &.next-page-container{
                    background-color: $gray-2022;
                }
            }
        }

        .ar-home-page {
            @media (min-width: 992px) and (max-width: 1200px) {
                padding-top: 40px;
            }

            @include media-breakpoint-down(sm) {
                padding-top: 70px;
            }
        }

        .side-menu-col {
            @include media-breakpoint-down(sm) {
                margin-top: 0;
            }

            .col-12{
                @include media-breakpoint-up(xl){
                    flex: 0 0 140% !important;
                    max-width: 140% !important;
                }
            }

            ul.side-menu {
                position: relative;
                z-index: 30 !important;

                @include media-breakpoint-down(sm){
                    min-width: calc(100vw - 30px);
                }

                li {
                    &::after {
                        right: 50%;
                    }

                    &.open {
                        &::after{
                            content: none;
                        }

                        .line{
                            border-color: $yellow-2022;
                        }

                        ul.sub-menu{
                            background-color: $light-bg-2022;
                            right: 0;
                            top: 0;

                            li.chapters{
                                a{
                                    &:hover{
                                        border-bottom: 2px solid $yellow-2022;
                                    }
                                }
                            }
                        }
                    }

                    &.grey {
                        opacity: .7;

                        &::after {
                            content: none;
                        }
                    }
                }
            }
        }

        .chapter-container {
            background-color: $light-bg-2022;

            .chart-block-container {
                .chart-element {
                    background-color: $white !important;

                    .bottom-label-container{
                        flex-wrap: wrap;
    
                        .inner{
                            margin-bottom: 8px;
                        }
                    }
                }
            }

            &.gray-bg {
                background-color: $light-bg-2022;

                .chart-block-container {
                    .chart-element {
                        background-color: $white !important;
                    }
                }

                .table-block {
                    .table-col {
                        background-color: $white !important;
                    }
                }

                .twocol-5-5 {
                    .table-block {
                        background-color: $white !important;
                    }
                }
            }
        }

        .standard-title-block {
            padding-top: 20px !important;
            margin-top: 0px !important;

            @include media-breakpoint-down(md) {
                padding-top: 0 !important;

            }
        }

        .prologue {
            .prologue-container {
                .read-more {
                    background-color: $yellow-2022;
                    border: none;
                    color: $white;
                    height: 40px;
                    text-transform: uppercase;
                    margin: 20px 0 0 0;
                }
            }

            @include media-breakpoint-up(lg) {
                &.article-open {
                    img {
                        margin-bottom: 20px;
                    }
                }

                .prologue-container {
                    .outer-container {
                        display: flex;

                        .col-12 {
                            flex: 0 0 60% !important;
                            max-width: 60% !important;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                        }

                        img {
                            // max-width: 40%;
                            min-height: 400px;
                        }
                    }
                }
            }
        }

        .link-block, .paragraph-block{
            a{
                color: $brown-2022;
            }
            svg{
                path{
                    &:first-child{
                        stroke: $brown-2022;
                    }
                    &:last-child{
                        fill: $brown-2022;
                    }
                }
            }
        }

        .image-link-block {
            .chapter {
                background-color: $yellow-2022;
            }
        }

        .subtitle-block {
            font-size: 18px;
            line-height: 27px;
            font-weight: 500;
        }

        .info-circle .number,
        .description {
            color: $light-bg-2022 !important;
        }

        .info-circle .description {
            sub{
                font-size: 75% !important;
            }
        }

        .focus-text-block .focus-text-container .text{
            color: $dark-brown-2021;
        }

        &.page-arstandardindexpage,
        &.page-arstandardpage {

            @include media-breakpoint-down(lg){
                &:not(.inngangur){
                    .chapter-container.first {
                        background-color: $gray_d1;
                    }
                }
            }

            .chapter-container.first {
                .circle-image.large-container{
                    background-color: $gray_d1;

                    @media (min-width: 992px){
                        height: 102vh;
                        margin-left: -20vw;
                        min-height: 1000px;
                        min-width: 80vw;
                    }

                    .chapter-image{
                        z-index: 0;

                        @media(max-width: 1220px){
                            object-position: right;
                        }

                        @media(max-width: 992px){
                            left: unset;
                            right: 0;
                        }
                    }

                    svg.circle-bg{
                        left: 0;
                        min-height: 100%;
                        z-index: 1;

                        @media(max-width: 992px){
                            display: none !important;
                        }
                        
                        path{
                            fill: $gray-2022;
                        }
                    }
                }
            }

            .chapter-container:not(.first),
            .chapter-container:not(:last-child) {
                padding-bottom: 60px !important;

                .anchor {
                    padding-bottom: 0 !important;
                }
            }

            .gri-body-container {
                .col-12{
                    background-color: $white;
                }

                .gri-table tbody {
                    tr.sub-heading-row td.gri-sub {
                        color: $moss-green-2022;
                    }

                    tr:nth-child(odd){
                        background-color: $light-bg-2022;
                    }
                }
            }
        }

        .circle-image-block{
            @include media-breakpoint-between(sm, md){
                margin: 0;

                .square-image{
                    .info-circle{
                        left: unset !important;
                    }
                }
            }
        }

        .static-image-block {
            margin-bottom: 80px;
            margin-top: 0;
        }

        .community-report-block {
            padding-bottom: 40px;
        }

        .image-list-block {
            .img-title {
                color: $green-2022 !important;
            }
        }

        .vinbud-arsins-block {
            .title-container .title {
                background-color: $yellow-2022 !important;
            }

            .store-name{
                color: $gray-2022;
            }
        }

        .table-block .table-col {
            background-color: $white !important;

            .title {
                color: $gray-2022 !important;
            }

            table{
                tr{
                    td{
                        &.grey-cell{
                            background-color: $light-bg-2022 !important;
                        }
                    }
                }
            }
        }

        .box-counter-block .small-box,
        .box-counter-block .large-box {
            background-color: $white !important;

            &.green{
                background-color: $green-2022 !important;

                .number, .line-under, .line-over{
                    color: $white !important;
                }
            }

            .line-over{
                padding-top: 20px;
            }

            .line-under{
                padding-bottom: 20px;
            }
        }

        .two-column-block {
            padding-bottom: 0 !important;

            .right-col{
                flex-direction: column;
            }

            img{
                max-width: 100%;
            }

            .chart-block-container,
            .chart-element {
                background-color: $white !important;
            }

            .chapter-container.gray-bg .table-block .table-col {
                background-color: $white !important;
            }

            .poster-block{
                @include media-breakpoint-down(lg) {
                    margin-bottom: 0;
                    margin-top: 0;

                    img{
                        width: auto;
                    }
                }
            }
        }

        &.vidskiptavinir,
        &.mannaudur,
        &.birgjar,
        &.eigandinn,
        &.samfelagid {
            &.page-arstandardindexpage {
                .chapter-container.first img.chapter-image {
                    @media (min-width:540px) and (max-width: 1200px) {
                        min-height: 70vh !important;
                    }

                    @media (max-width: 400px) {
                        min-height: 340px !important;
                        width: 120% !important;
                        top: -50px;
                    }

                    @media (max-width: 380px) {
                        width: 100% !important;
                        bottom: -99vh !important;
                    }
                }
            }

            .first {
                &.white-2021 {
                    color: $white !important;

                    .text-col {
                        .parent {
                            margin-bottom: 60px;
                            position: relative;
                            color: $white;
                            border-bottom: none;

                            &::after {
                                background-color: $white;
                                bottom: -4px;
                                content: '';
                                left: 0;
                                height: 1px;
                                position: absolute;
                                width: 140px;
                            }

                            @include media-breakpoint-down(md) {
                                margin-bottom: 0;
                            }
                        }

                        p {
                            position: relative;

                            &::after {
                                background-color: $white;
                                bottom: -26px;
                                content: '';
                                left: 0;
                                height: 1px;
                                position: absolute;
                                width: 140px;

                                @include media-breakpoint-down(md) {
                                    content: none;
                                }
                            }
                        }
                    }
                }
            }

            .second {
                padding-top: 60px;
            }
        }

        .sidemenu-container {
            .current-page {
                font-weight: 700 !important;
            }

            ul {
                li {
                    &.active {
                        &::before {
                            background-color: $yellow-2022;
                        }
                    }

                    a {
                        font-weight: 700;
                    }
                }
            }
        }

        .sidetext {
            color: $white !important;
            font-family: 'Gotham-thin' !important;
            font-size: 100px !important;
            font-weight: 600 !important;
            letter-spacing: 10px;
        }

        .chapter-container {
            .titleblock {
                .parent {
                    border-bottom: 2px solid $yellow-2022;
                    color: $gray-2022;
                    display: inline-block;
                    padding-bottom: 0;
                    font-weight: 700;
                }
                
                .child {
                    font-weight: 400;
                    color: $yellow-2022 !important;
                    margin-top: 20px;
                }
            }
        }

        .sidemenu-container {
            .current-page {
                color: $yellow-2022!important;
            }
        }

        &.inngangur {
            .sidemenu-container {
                .menu-items {
                    color: $gray-2022;

                    .sub-menu {
                        .chapters {
                            a {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}