.twocol-3-7{
    @media (max-width: 992px){
        .col-lg-3{
            order: 2;
        }
    }
    .info-circle{
        margin-bottom: unset;
        margin-top: 130px;
        @media (max-width: 1200px){
            width: 210px;
            height: 210px;
        }
    }
}