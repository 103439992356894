.focus-text-block{
    align-items: center;
    height: 100%;
    margin-bottom: 60px;
    .focus-text-container{  
        .text{
            color: $gold-80;
            padding: 40px 30px;
            p{
                font-size: 32px;
                line-height: 40px;
                @media (max-width: 992px){
                    font-size: 28px;
                    line-height: 35px;
                }
                @media (max-width: 768px){
                    font-size: 24px;
                    line-height: 30px;
                }
            }
            @media (max-width: 992px){
                max-width: 450px;
                margin-top: 60px;
            }
            @media (max-width: 768px){
                margin-top: 40px;
                max-width: 100%;
            }
        }
    }
}