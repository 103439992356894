.page-argripage{
    .white-circle{
        position: absolute;
        right: 41%;
        top: -22%;
        background-color: #fff;
        height: 1094px;
        width: 1094px;
        border-radius: 100%;
        z-index: 0;
        @media (max-width: 768px){
            display: none;
        }
    }
    .standard-title-block{
        margin-top: 260px;
        .parent{
            display: none;
        }
        .child{
            font-size: 60px !important;
            line-height: 66px !important;
        }
    }
    .subtitle-block{
        margin-bottom: 160px;
    }
    .paragraph-block{
        margin-bottom: 80px;
    }
    .bottom-menu-container{
        background-color: $white;
    }
}