.top-menu-row{
    &:first-of-type{
        margin-top: 160px;
        @media (max-width: 768px){
            margin-top: 8   0px;
        } 
    }
    @media (max-width: 768px){
        margin-top: 58px;
        &:last-of-type{
            padding-bottom: 210px;
        }
    }
}
.top-menu{
    @media (max-width: 992px){
        margin-top: 60px;
    }
    @media (max-width: 768px){
        margin-top: 40px;
    }
    &.sub-top{
        margin-top: 80px;
        @media (max-width: 768px){
            margin-top: 20px;
        }
    }
    .menu-title{
        color: $red;
        font-size: 13px;
        line-height: 14px;
        font-weight: 700;
        margin-right: 10px;
        white-space: nowrap;
        @media(max-width: 992px){
            font-size: 12px;
            line-height: 13px
        }
        @media(max-width: 768px){
            font-size: 11px;
            line-height: 12px;
        }
    }
    .line{
        background-color: $red;
        height: 2px;
        width: 100%;
        margin-bottom: 4px;
        @media (max-width: 992px){
            margin-bottom: 2px;
        }
    }
    .top-ul-container{
        ul{
            padding: 30px 0 0 0;
            @media(max-width: 992px){
                padding: 25px 0 0 0;
            }
            @media(max-width: 768px){
                padding: 20px 0 0 0;
            }
            li{
                list-style-type: none;
                padding-bottom: 9px;
                line-height: 14px;
                white-space: nowrap;
                a{
                    text-decoration: none;
                    &:hover{
                        border-bottom: 2px solid $red;
                        text-decoration: none;
                        cursor: pointer;
                    }
                    span.title{
                        font-size: 14px;
                        line-height: 15px;
                        color: $font;
                        @media(max-width: 992px){
                            font-size: 12px;
                            line-height: 13px
                        }
                        @media(max-width: 768px){
                            font-size: 11px;
                            line-height: 12px;
                        }
                    }
                }
            }
            &.two-cols{
                display: grid;
                grid-template-columns: auto auto;
                grid-column-gap: 60px;
            }
        }
    }
    &.color-green{
        .menu-title{
            color: $gray-d1;
        }
        .line{
            background-color: $gray-d1;
        }
    }
}