.video-poster-block{
    padding: 0;
    @media (max-width: 540px){
        min-width: 100vw;
    }
    .video-block{
        margin-top: 60px;
        .video-container{
            width: 100%;
            padding-top: 40px;
            padding-bottom: 40px;
            video{
                max-width: 100%;
            }
        }
        .small-video-container{
            max-width: 540px;
            height: 400px;
            @media (max-width: 1200px){
                width: 100%;
                height: 380px;
            }
            @media (max-width: 768px){
                height: 259px;
                margin-left: -15px;
            }
            video{
                height: 100%;
                width: 100%;
                z-index: 1;
                position: relative;
                object-fit: cover;
            }
        }
        .golden-circle{
            width: 445px;
            opacity: .8;
            height: 445px;
            border-radius: 100%;
            position: absolute;
            left: -37%;
            top: -20%;
            @media (max-width: 992px){
                display: none;
            }
            &.gold-50{
                background-color: $gold-50;
            }
            &.beige{
                background-color: $beige;
            }
            &.blue-80{
                background-color: $blue-80;
            }
            &.blue-2021{
                background-color: $light-blue-2021;
            }
            &.dark-blue-2021{
                background-color: $blue-2021;
            }
            &.pink-2021{
                background-color: $pastel-pink-2021;
            }
            &.brown-2021{
                background-color: $brown-2021;
            }
            &.yellow-2021{
                background-color: $yellow-2021;
            }
            &.green-2021{
                background-color: $green-2021;
            }
            &.yellow-2022{
                background-color: $yellow-2022;
            }
            &.green-2022{
                background-color: $green-2022;
            }
            &.light-green-2022{
                background-color: $light-green-2022;
            }
            &.moss-green-2022{
                background-color: $moss-green-2022;
            }
            &.gray-2022{
                background-color: $gray-2022;
            }
            &.brown-2022{
                background-color: $brown-2022;
            }
            &.secondary-gray-2022{
                background-color: $secondary-gray-2022;
            }
            &.secondary-green-2022{
                background-color: $secondary-green-2022;
            }
            &.secondary-yellow-2022{
                background-color: $secondary-yellow-2022;
            }
            &.secondary-orange-2022{
                background-color: $secondary-orange-2022;
            }
            &.secondary-brown-2022{
                background-color: $secondary-brown-2022;
            }
            &.secondary-red-2022{
                background-color: $secondary-red-2022;
            }
        }
    }
    .poster-block{
        position: relative;
        margin-top: 60px;
        @media (min-width: 992px){
            height: 400px;
        }
        @media (max-width: 992px){
            margin-bottom: 60px;
        }
        @media (max-width: 768px){
            margin-bottom: 40px;
            margin-left: -15px;
        }
        .golden-circle{
            width: 445px;
            height: 445px;
            border-radius: 100%;
            position: absolute;
            left: -37%;
            top: -20%;
            @media (max-width: 992px){
                display: none;
            }
            &.gold-50{
                background-color: $gold-50;
            }
            &.beige{
                background-color: $beige;
            }
            &.blue-80{
                background-color: $blue-80;
            }
            &.blue-2021{
                background-color: $light-blue-2021;
            }
            &.dark-blue-2021{
                background-color: $blue-2021;
            }
            &.pink-2021{
                background-color: $pastel-pink-2021;
            }
            &.brown-2021{
                background-color: $brown-2021;
            }
            &.yellow-2021{
                background-color: $yellow-2021;
            }
            &.green-2021{
                background-color: $green-2021;
            }
        }
        .poster-container{
            width: 100%;
            max-width: 540px;
            height: 100%;
            z-index: 1;
            max-height: 400px;
            position: relative;
            @media (max-width: 992px){
                max-width: 100%;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}