// Same base as 2020 but created to change the font

html{
    font-size: 62.5%;
}
body{
    position: relative;
    overflow-x: hidden;
    height: 100%;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $font;
    min-height: 900px;
    &.body-fixed{
        position: fixed;
        width: 100vw;
        .bottom-menu-container{
            z-index: 0;
        }
    }
    .old{
        display: none;
    }

    .chapter-container{
        padding-bottom: 80px;
        z-index: 1;
      
        &:not(.gray-bg){
            background-color: $white;
        }
        &.first{
            padding-top: 0 !important;
            &::before{
                background-color: $white;
            }
            @media(max-width: 1200px){
                overflow: hidden !important;
            }
            .right-col{
                padding-top: 0 !important;
            }
            &.blue{
                &::after{
                    background-color: $blue-50;
                }
                svg.circle-bg{
                    path{
                        fill: $blue-50;
                    }
                }
                .chapter-content{
                    &::after{
                        background-color: $blue-50;
                    }
                }
            }
            &.green{ 
                &::after{
                    background-color: $green-50;
                }
                svg.circle-bg{
                    path{
                        fill: $green-50;
                    }
                }
                .chapter-content{
                    &::after{
                        background-color: $green-50;
                    }
                }
            }
            &.beige{
                &::after{
                    background-color: $beige;
                }
                svg.circle-bg{
                    path{
                        fill: $beige;
                    }
                }
                .chapter-content{
                    &::after{
                        background-color: $beige;
                    }
                }
            }
            &.red{
                &::after{
                    background-color: $red-80;
                }
                svg.circle-bg{
                    path{
                        fill: $red-80;
                    }
                }
                .chapter-content{
                    &::after{
                        background-color: $red-80;
                    }
                }
            }
            &.vidskiptavinir{
                &::after{
                    background-color: $pastel-pink-2021;
                }
                svg.circle-bg{
                    path{
                        fill: $pastel-pink-2021;
                    }
                }
                .chapter-content{
                    &::after{
                        background-color: $pastel-pink-2021;
                    }
                }
            }
            &.mannaudur{
                &::after{
                    background-color: $green-2021;
                }
                svg.circle-bg{
                    path{
                        fill: $green-2021;
                    }
                }
                .chapter-content{
                    &::after{
                        background-color: $green-2021;
                    }
                }
            }
            &.samfelagid{
                &::after{
                    background-color: $blue-2021;
                }
                svg.circle-bg{
                    path{
                        fill: $blue-2021;
                    }
                }
                .chapter-content{
                    &::after{
                        background-color: $blue-2021;
                    }
                }
            }
            &.birgjar{
                &::after{
                    background-color: $yellow-2021;
                }
                svg.circle-bg{
                    path{
                        fill: $yellow-2021;
                    }
                }
                .chapter-content{
                    &::after{
                        background-color: $yellow-2021;
                    }
                }
            }
            &.eigandinn{
                &::after{
                    background-color: $brown-2021;
                }
                svg.circle-bg{
                    path{
                        fill: $brown-2021;
                    }
                }
                .chapter-content{
                    &::after{
                        background-color: $brown-2021;
                    }
                }
            }
            .subtitle-block{
                margin-bottom: 160px;
            }
            .chapter-content{
                padding-top: 160px;
                @include media-breakpoint-down(md){
                    padding-top: 60px;
                }
            }
            .right-col{
                @media (min-width: 992px){
                    flex: 0 0 58.33333% !important;
                    max-width: 58.33333% !important;
                }
                @media (max-width: 992px){
                    .outer-container{
                        display: flex;
                        justify-content: center;
                    }
                }
                .circle-image-block{
                    padding-bottom: 0;
                    @media (min-width: 992px){
                        width: 100%;
                        height: 100%;
                    }
                    .col-12{
                        padding: 0;
                    }
                    .circle-image{
                        &.large-container{
                            height: 100%;
                            width: 100%;
                            min-height: calc(100vh + 220px);
                            margin-top: -220px;
                            margin-left: -40px;
                            min-width: 60vw;
                            @media (max-width: 1440px) and (min-width: 1201px){
                                margin-left: -5%;
                                min-width: 60vw;
                            }
                            @media (max-width: 1200px) and (min-width: 993px){
                                min-width: 128vw;
                                margin-left: -508px;
                            }
                            @media (max-width: 992px){
                                margin-left: 0;
                                min-width: 100vw;
                                margin-top: -14%;
                                max-height: 630px;
                                min-height: unset;
                                align-items: baseline;
                            }
                            @media (max-width: 540px){
                                min-height: unset;
                                min-width: 100%;
                                margin-top: 0;
                                margin-left: 0;
                            }
                            .large{
                                margin-top: 0;
                                @media (min-width: 768px) and (max-width: 992px){
                                    margin-left: auto;
                                }
                            }
                        }
                        img{
                            width: 100%;
                            height: 100%;
                            @media (max-width: 992px){
                                max-height: unset;
                                max-width: unset;
                                height: auto;
                            }
                            @media(max-width: 540px){
                                max-height: 475px;
                                height: 475px;
                            }
                        } 
                        &.large-container{
                            @media (min-width: 540px) and (max-width: 992px){
                                margin-left: auto;
                            }
                        }   
                    }
                }
            }
            .text-col{
                z-index: 100;
                padding-left: 30px;
                @include media-breakpoint-down(md){
                    flex: 0 0 90% !important;
                    max-width: 90% !important;
                }
                .parent{
                    color: $gray_d1;
                }
                .child{
                    color: $white;
                }
            }
        }
        &.gray-bg{
            background-color: $body;
        }
    }
    @include font-styles(18px, 28px, normal, normal, normal, 'aaux');

    button, a{
        &:focus{
            outline: none !important;
        }
    }
}
h1{
    font-size: 50px;
    line-height:56px;
    font-weight: 700;
    @media (max-width: 992px){
        font-size: 42px;
        line-height: 46px;
    }
    @media (max-width: 768px){
        font-size: 32px;
        line-height: 36px;
    }
}
h2{
    font-size: 50px;
    line-height:56px;
    font-weight: 700;
    @media (max-width: 992px){
        font-size: 42px;
        line-height: 46px;
    }
    @media (max-width: 768px){
        font-size: 32px;
        line-height: 36px;
    }
}
h3{
    font-size: 32px;
    line-height:40px;
    font-weight: 700;
    margin-bottom: 10px;
    @media (max-width: 992px){
        font-size: 28px;
        line-height: 35px;
    }
    @media (max-width: 768px){
        font-size: 24px;
        line-height: 30px;
    }
}
h4{
    font-size: 22px;
    line-height: 27px;
    font-weight: 700;
    margin-bottom: 10px;
    @media (max-width: 992px){
        font-size: 18px;
        line-height: 22px;
    }
    @media (max-width: 768px){
        font-size: 14px;
        line-height: 17px;
    }
}
p{
    font-size: 18px;
    line-height: 30px;
    @media (max-width: 992px){
        font-size: 16px;
        line-height: 27px;
    }
    @media (max-width: 768px){
        font-size: 14px;
        line-height: 23px;
    }
    .sublabel{
        bottom: -.25em;
        font-size: 75%;
        line-height: 0;
        vertical-align: baseline;
    }
}
.asterisk{
    font-size: 13px;
    line-height: 21px;
    font-style: italic;
    margin-top: 20px;
    @media (max-width: 992px){
        font-size: 11px; 
        line-height: 17px;
        margin-top: 15px;
    }
    @media (max-width: 768px){
        font-size: 10px;
        line-height: 16px;
        margin-top: 10px;
    }
}