.page-arstandardindexpage, .page-arstandardpage{
    .chapter-container.first{
        &.big-circle{
            min-height: 100vh;
            max-height: 100vh;
        }
        @media (max-width: 992px){
            img{
                min-width: 100% !important;
            }
        }
        .chapter-content{
            &::after{
                z-index: 1;
                content: '';
                position: absolute;
                height: 100vh;
                width: 40vw;
                top: -40px;
                left: 0;
                @media (min-width: 1200px){
                    @media screen and (orientation:portrait) {
                        width: 20vw;
                    }
                }
                @include media-breakpoint-down(lg){
                    width: 26vw;
                }
            }
            @media (max-width: 992px){
                &::after{
                    top: -21px;
                    width: 100vw;
                    height: 20vh;
                }
            }
            @include media-breakpoint-down(sm){
                &::after{
                    width: 100vw;
                    height: 40vh;
                    top: -20px;
                    left: 0;
                }
            }
        }
        .two-column-block{
            @media (max-width: 992px){
                .twocol-5-6{
                    .text-col{
                        order: 2 !important;
                    }
                    .right-col{
                        order: 1 !important;
                        img{
                            min-height: 100vh;
                        }
                    }
                }
            }
        }
        svg.circle-bg{
            display: block;
            height: 100vh;
            position: absolute;
            left: -370px;
            top: 20px;
            width: auto;
            @media (min-width: 1200px){
                @media screen and (orientation:portrait) {
                    left: -70%;
                }
            }
            @include media-breakpoint-down(lg){
                left: 17vw;
                @media screen and (orientation:portrait) {
                    left: -120px;
                }
            }
            @include media-breakpoint-down(md){
                width: 100vw;
                top: -9vh;
                left: 0;
                height: auto;
                min-height: 60vh;
            }
            @media (max-width: 540px){
                left: 0;
                top: -100px;
                width: 100vw;
                height: auto;
                min-height: 45vh;
            }
        }
        img.chapter-image{
            position: fixed;
            top: 0;
            width: auto !important;
            height: 100% !important;
            object-fit: cover;
            object-position: center center;
            right: 0;
            z-index: -1;
            @include media-breakpoint-down(md){
                position: absolute;
                min-height: 100vh !important;
                left: 0;
                right: unset;
                margin-top: 417px !important;
            }
            @media (max-width: 540px){
                top: unset;
                bottom: -99vh;
                margin-top: 45vh!important;
                height: auto !important;
                max-width: 100vw;
                min-height: 45vh !important;
            }
        }
    }
    .chapter-container:not(.first){
        &::after{
            content: none !important;
        }
        svg.circle-bg{
            display: none !important;
        }
    }
    .gray-circle-left, .gray-circle-left-2,
    .gray-circle-right, .gray-circle-right-2{
        position: absolute;
        left: -20%;
        top: 3%;
        height: 499px;
        width: 499px;
        border: 2px solid $gray_l1;
        border-radius: 100%;
        z-index: 2;
    }
    .gray-circle-left-2{
        top: 2%;
        left: -25%;
    }
    .gray-circle-right{
        left: unset;
        top: 11%;
        right: -7%;
    }
    .gray-circle-right-2{
        left: unset;
        top: 12%;
        right: -10%;
    }
    .sidemenu-container{
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        float: right;
        z-index: 3;
        width: 240px;
        display: none;
        right: 0;
        @media (max-width: 1360px){
            display: none !important;
        }
        .current-page{
            color: $red;
            font-size: 14px;
            line-height: 20px;
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: 10px;
            padding-left: 20px;
            max-width: 200px;
        }
        ul{
            max-width: 200px;
            li{
                list-style-type: none;
                margin-bottom: 15px;
                line-height: 15px;
                position: relative;
                &::before{
                    content: '';
                    position: absolute;
                    height: 8px;
                    width: 8px;
                    top: 5px;
                    left: -20px;
                    background-color: $gray_l2;
                    border-radius: 50%;
                }
                &.active{
                    &::before{
                        background-color: $red;
                    }
                }
                a{
                    font-size: 14px;
                    color: $font;
                }
            }
        }
    }        
    .chapter-container{
        position: relative;
        overflow: hidden;
        .anchor{
            padding-bottom: 40px;
        }
        &.first{
            padding-top: 160px;
            position: relative;
            overflow: unset;
            z-index: 1;
            &.gray-bg{
                &::before{
                    background-color: $white !important;
                }
            }
            .sidetext{
                display: none;
            }
            + .chapter-container{
                z-index: 2 !important;
            }
            &.big-circle{
                .two-column-block{
                    padding: 0;
                }
            }
        }
        @media (max-width: 992px){
            padding-top: 70px;
        }
        @media (max-width: 768px){
            padding-top: 0;
            padding-bottom: 25px;
        }
        &.last-of-type{
            padding-bottom: 80px;
            @media (max-width: 992px){
                padding-bottom: 100px;
            }
        }
        .anchor{
            padding-bottom: 40px;
            @include media-breakpoint-down(md){
                padding-bottom: 20px;
            }
        }
        .sidetext{
            font-size: 200px;
            line-height: 200px;
            font-weight: 700;
            color: $chapter_side;
            transform: translateX(100%) rotate(90deg);
            position: absolute;
            right: -0px;
            top: 25px;
            transform-origin: top left;
            white-space: nowrap;
            @media (max-width: 992px){
                display: none;
            }
        }
        .chapter-content{
            position: relative;
            z-index: 1;
        }
    }
    .gri-body-container{
        background-color: $white;
        padding-top: 80px;
        padding-bottom: 80px;
        .gri-table{
            width: 100%;
            /* Force table to not be like tables anymore */
            table, thead, tbody, th, td, tr { 
                @media only screen and (max-width: 768px),
                (min-device-width: 768px) and (max-device-width: 1024px){
                    display: block; 
                }
            }
            tbody{
                tr{
                    height: 46px;
                    border-bottom: 1px solid $font;
                    border-top: 1px solid $font;
                    &:nth-child(odd){
                        background-color: $body;
                    }
                    @media (max-width: 768px){
                        height: auto;
                        padding: 10px 0;
                        background-color: transparent !important;
                        border-bottom: 1px solid $font;
                        border-top: none;
                    }
                    &.gri-title-row{
                        border: none;
                        background-color: $white !important;
                        height: 100px;
                        @media (max-width: 768px){
                            height: 30px;
                            margin-top: 40px;
                        }
                        td{
                            vertical-align: bottom;
                            font-size: 22px;
                            line-height: 27px;
                            font-weight: 500;
                            @media (max-width: 768px){
                                padding-left: 0;
                            }
                        }
                    }
                    &.sub-heading-row{
                        border-top: none;
                        background-color: $white !important;
                        height: 75px;
                        @media (max-width: 992px){
                            height: 70px;
                        }
                        @media (max-width: 768px){
                            border-bottom: none !important;
                            height: 41px;
                            padding-bottom: 0;
                            margin-bottom: -10px;
                        
                        }
                        td{
                            font-weight: 700;
                            font-size: 10px;
                            line-height: 41px;
                            vertical-align: bottom;
                            &.gri-sub{
                                font-size: 14px;
                                color:  $red;
                                white-space: nowrap;
                                @media(max-width: 768px){
                                    padding-left: 0;
                                }
                            }
                            &.hide{
                                @media (max-width: 768px){
                                    position: absolute;
                                    top: -9999px;
                                    left: -9999px;
                                }
                            }
                        }
                    }
                    &.gri-row{
                        @media only screen and (max-width: 767px),
                        (min-device-width: 768px) and (max-device-width: 1024px){
                            margin-bottom: -11px;
                            td{
                                /* Behave  like a "row" */
                                border: none;
                                position: relative;
                                padding: 4px 0;
                                height: auto;
                                line-height: 18px;
                                display: flex;
                                &:nth-child(even){
                                    background-color: $body;
                                }
                                &::before{
                                    position: relative;
                                    padding-right: 10px; 
                                    font-size: 12px;
                                    font-weight: 700;
                                    width: 45%;
                                    min-width: 45%;
                                    padding-right: 16%;
                                }
                                /*Label the data*/
                                &:nth-of-type(0):before { content: ""; }
                                &:nth-of-type(1):before { content: ""; }
                                &:nth-of-type(2):before { content: attr(data-shb); }
                                &:nth-of-type(3):before { content: "Upplýsingar"; }
                                &:nth-of-type(4):before { content: "Staðsetning"; }
                                &:nth-of-type(5):before { content: "Heimsmarkmið"; }
                            }  
                        }
                    }
                    td{
                        font-size: 12px;
                        line-height: 15px;
                        padding-right: 10px;
                        &.gri-code{
                            font-size: 14px;
                            line-height: 17px;
                            @media (max-width: 768px){
                                border-top: 1px solid $font;
                                padding-left: 0;
                                height: 40px !important;
                                line-height: 40px;
                                &::before{
                                    display: none;
                                }
                            }
                        }
                        &.location{
                            a{
                                text-decoration: none;
                                color: $gray_d1;
                                font-weight: 500;
                            }
                        }
                        &.sdg{
                            a{
                                text-decoration: none;
                                color: $gray_d1;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
    }
}