.page-arhomepage{
    background-color: $body;
    overflow: hidden !important;
    -webkit-overflow-scrolling: hidden !important;
    @include media-breakpoint-down(md){
        @media screen and (orientation:landscape) {
            overflow: scroll !important;
            -webkit-overflow-scrolling: scroll !important;
            background-color: $red-80;
        }
    }
    .navbar-brand{
        svg{
            g > path{
                fill: $white;
            }
        }
    }
    .pdf{
        color: $white !important;
        @media (min-width: 993px){
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        }
    }
    .navbar-toggler{
        >div{
            background-color:  $white !important;
            @media(min-width: 993px){
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
            }
        }
    }
}
.background-container{
    min-width: 100vw;
    max-width: 100vw;
    height: 100vh;
    min-height: 100%;
    position: absolute;
    overflow-x: hidden;
    @media (max-width: 540px){
        position: fixed;
    }
    .background-image{
        top: 0;
        width: 100%;
        z-index: -1;
        justify-content: flex-end !important;
        height: 100%;
        position: relative;
        body.year-2021 &{
            svg.red{

                path{
                    fill: $light-blue-2021;
                }
            }
            &::after{
                background-color: $light-blue-2021 !important;
            }
        }
        &::after{
            content: '';
            position: absolute;
            height: 100vh;
            min-height: 100%;
            width: 30vw;
            left: 0;
            background-color: $red_80;
        }
        @include media-breakpoint-down(md){
            @media screen and (orientation:landscape) {
                display: none !important;
                &::after{
                    content: none !important;
                }
            }
        }
        @media (max-width: 992px){
            img{
                min-width: 100% !important;
            }
        }
        @include media-breakpoint-down(sm){
            right: 0 !important;
            left: unset !important;
            &::after{
                width: 100vw;
                height: 40vh;
                top: 0;
                left: 0;
                min-height: unset;
            }
        }
        svg.red{
            height: 100vh;
            min-height: 100%;
            position: absolute;
            left: 25%;
            width: auto;
            @media (max-width: 1580px){
                left: 17%;
            }
            @include media-breakpoint-down(md){
                @media screen and (orientation:landscape) {
                    display: none !important;
                }
            }
            @include media-breakpoint-down(md){
                min-height: unset;
                left: 4%;
            }
            @include media-breakpoint-down(sm){
                left: 0;
                top: 4vh;
                width: 100vw;
                height: auto;
                min-height: 55vh;
            }
        }
        img{
            width: auto;
            max-width: 100%;
            object-fit: cover;
            object-position: center center;
            height: 100%;
            position: absolute;
            z-index: -1;
            right: 0;
            @include media-breakpoint-down(sm){
                top: unset;
                bottom: 0;
                max-height: 50vh;
            }
        }
    }
}
.ar-home-page{
    position: relative;
    @media (min-width: 993px){
        height: 95vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 0;
    }
    @media (max-width: 1300px){
        padding-top: 120px;
    }
    @media (max-width: 992px){
        margin-right: 0;
        height: 100vh;
        max-width: 100%;
    }
    @media (min-width: 540px) and (max-width: 768px){
        margin-left: 40px;
    }
    @media (max-width: 540px){
        height: 100vh;
        position: fixed;
        padding-top: 80px;
    }
    .year-container{
        position: absolute;
        right: 20px;
        .year-background{
            img{
                width: 65%;
                max-width: 864px;
                position: fixed;
                bottom: 80px;
                right: 50px;
                body.year-2021 &{
                    width: 26%;
                    max-width: 21%;
                    bottom: 30px;
                    right: 100px;
                    @media (max-width: 1200px){
                        max-width: 24%;
                        bottom: 30px;
                    }
                }
                @media (max-width: 1200px){
                    bottom: 66px;
                    width: 614px;
                }
                @media (max-width: 1200px) and (min-width: 992px){
                    max-width: 60%;
                }
                @include media-breakpoint-down(md){
                    @media screen and (orientation:landscape) {
                        display: none;
                    }
                }
                @include media-breakpoint-down(sm){
                    display: none;
                }
            }
        }
    }
    .title, .year{
        display: inline;
        color: $white;
        body.year-2021 &{
            color: $dark-brown-2021;
            font-weight: 400;
            @include media-breakpoint-down(sm){
                font-size: 23px;
            }
        }
        @media(max-width: 992px){
            z-index: 1;
            position: relative;
        }
    }
    .year{
        margin-left: 20px;
        @media (max-width: 540px){
            position: absolute;
            margin-left: 8px;
        }
    }
    .red-circle{
        height: 100%;
        width: 100%;
        background-color: transparent;
        @include media-breakpoint-down(md){
            @media screen and (orientation:landscape) {
                .side-menu{
                    margin-top: 60px !important;
                }
            }
        }
    }
    .bottom-menu-container{
        position: fixed;
        bottom: 0;
        left: -10px;
        min-width: 100vw;
        margin-bottom: 0;
        @media (max-width: 1200px){
            transform: translateY(-44px) !important;
        }
        .bottom-menu{
            max-width: unset !important;
        }
        .row{
            max-width: 1110px;
        }
    }
}

