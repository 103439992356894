.info-circle-block{
    margin-bottom: 100px;
    margin-top: 60px;
    @media (max-width: 992px){
        display: flex;
        justify-content: center;
        margin-bottom: 0;
        @media (min-width: 580px){
            .col-lg-3{
                flex: 0 0 50% !important;
                max-width: 50% !important;
            }
        }
    }
}
.info-circle{
    width: 255px;
    max-width: 255px;
    height: 255px;
    border-radius: 50%;
    text-align: center;
    padding: 24px;
    @media (max-width: 1200px){
        height: 210px;
        width: 210px;
    }
    @media (max-width: 768px){
        margin: 20px auto;
    }
    &.square{
        border-radius: 0 !important;
    }
    sub{
        font-size: 30px;
        @media (max-width: 1200px){
            font-size: 25px;
        }
        @media (max-width: 768px){
            font-size: 20px;
        }
    }
    .number, .description, .first-line{
        color: $white;
        font-weight: 700;
    }
    .number{
        font-size: 60px;
        line-height: 66px;
        @media (max-width: 1200px){
            font-size: 50px;
            line-height: 56px;
        }
        @media (max-width: 768px){
            font-size: 40px;
            line-height: 46px;
        }
    }
    .description{
        font-size: 18px;
        line-height: 24px;
        @media (max-width: 1200px){
            font-size: 18px;
            line-height: 22px;
        }
        @media (max-width: 992px){
            font-size: 16px;
        }
        @media (max-width: 768px){
            font-size: 12px !important;
            line-height: 16px !important;
        }
    }
    &.gold{
        background-color: $gold;
    }
    &.gold-80{
        background-color: $gold-80;
    }
    &.red{
        background-color: $red;
    }
    &.red-80{
        background-color: $red-80;
    }
    &.blue{
        background-color: $blue;
    }
    &.green-50{
        background-color: $green-50;
    }
    &.green-80{
        background-color: $green-2020-80;
    }
    &.blue-80{
        background-color: $blue-80;
    }
    &.brown{
        background-color: $brown;
    }
    &.brown-80{
        background-color: $brown-80;
    }
    &.brons-2020{
        background-color: $brons-2020;
    }
    &.brons-2020-80{
        background-color: $brons-2020-80;
    }
    &.blue-2021{
        background-color: $light-blue-2021;
    }
    &.dark-blue-2021{
        background-color: $blue-2021;
    }
    &.pink-2021{
        background-color: $pastel-pink-2021;
    }
    &.brown-2021{
        background-color: $brown-2021;
    }
    &.yellow-2021{
        background-color: $yellow-2021;
    }
    &.green-2021{
        background-color: $green-2021;
    }
    &.yellow-2022{
        background-color: $yellow-2022;
    }
    &.green-2022{
        background-color: $green-2022;
    }
    &.light-green-2022{
        background-color: $light-green-2022;
    }
    &.moss-green-2022{
        background-color: $moss-green-2022;
    }
    &.gray-2022{
        background-color: $gray-2022;
    }
    &.brown-2022{
        background-color: $brown-2022;
    }
    &.secondary-gray-2022{
        background-color: $secondary-gray-2022;
    }
    &.secondary-green-2022{
        background-color: $secondary-green-2022;
    }
    &.secondary-yellow-2022{
        background-color: $secondary-yellow-2022;
    }
    &.secondary-orange-2022{
        background-color: $secondary-orange-2022;
    }
    &.secondary-brown-2022{
        background-color: $secondary-brown-2022;
    }
    &.secondary-red-2022{
        background-color: $secondary-red-2022;
    }
    &.yellow-2023{
        background-color: $yellow-2023;
    }
    &.burgundy-2023{
        background-color: $burgundy-2023;
    }
    &.moss-green-2023{
        background-color: $moss-green-2023;
    }
    &.green-2023-l2{
        background-color: $green-2023-l2;
    }
    &.green-2023{
        background-color: $green-2023-l2;
    }
    .green-2023-bright {
        background-color: $green-2023-bright;
    }
    &.grey-2023{
        background-color: $grey-2023;
    }
    &.brown-2023{
        background-color: $brown-2023;
    }
    &.secondary-green-2023-d2{
        background-color: $secondary-green-2023-d2;
    }
    &.secondary-yellow-2023-d{
        background-color: $secondary-yellow-2023-d;
    }
    &.secondary-yellow-2023-l{
        background-color: $secondary-yellow-2023-l;
    }
    &.secondary-blue-2023{
        background-color: $secondary-blue-2023;
    }

    &.red-2024 {
        background-color: $red-2024;
    }
    &.red-l1-2024 {
        background-color: $red-l1-2024;
    }
    &.red-l2-2024 {
        background-color: $red-l2-2024;
    }
    &.yellow-2024 {
        background-color: $yellow-2024;
    }
    &.grey-2024 {
        background-color: $grey-2024;
    }

    
    &.blue50{
        background-color: $blue-square;
        .description, .number, .first-line{
            color: $gray_d1;
        }
    }
}