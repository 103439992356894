.subtitle-block{
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px;
    @media (max-width: 992px){
        font-size: 28px;
        line-height: 35px;
    }
    @media (max-width: 768px){
        font-size: 24px;
        line-height: 30px;
    }
}
.subtitle-block + .two-column-block {
    margin-top: -60px;
}