.nav-container{
    height: 100px;
    background-color: transparent;
    min-width: 100vw;
    position: fixed;
    top: 0;
    opacity: 1;
    z-index: 12;
    justify-content: center;
    display: flex;
    body.white-logo &{
        &:not(.menu-open){
            nav.navbar{
                &:not(.nav-down){
                    .navbar-brand{
                        svg{
                            
                            path{
                                fill: $white;
                            }
                        }
                    }
                }
            }
            .pdf{
                color: $white !important;
                @media (min-width: 993px){
                    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
                }
            }
            .navbar-toggler{
                >div{
                    background-color:  $white !important;
                    @media(min-width: 993px){
                        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
                    }
                }
            }
        }
    }
    &.menu-open{
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: $body;
        left: 0;
        top: 0;
        z-index: 40;
        min-width: 100%;
        display: flex;
        justify-content: center;
        @media (max-width: 768px){
            overflow: scroll;
        }
        &::before{
            content: none;
        }
        .pdf{
            color: $gray_d1 !important;
        }
        .navbar-toggler{
            >div{
                background-color:  $gray_d1 !important;
            }
        }
        .big-circle{
            position: absolute;
            right: 47%;
            top: -18%;
            background-color: $white;
            height: 1094px;
            width: 1094px;
            border-radius: 100%;
            z-index: 0;
            @media (max-width: 768px){
                display: none;
            }
        }
        .gray-circle-right{
            z-index: 0; 
            top: unset;
            bottom: 11%;
        }
        .gray-circle-right-2{
            top: unset;
            z-index: 0;
            bottom: 12%;
        }
        nav.navbar{
            height: 100px;
            position: fixed;
            display: flex !important;
            button.navbar-toggler{
                padding-top: 10px;
                @media (max-width: 768px){
                    margin-right: 0;
                }
                div{
                    &:nth-of-type(even){
                        width: 0;
                        transition: width .3s ease-in-out;
                    }
                    &:first-of-type{
                        transform: translateY(6px) rotate(-45deg);
                        transition: transform .3s ease-in-out;
                    }
                    &:last-of-type{
                        transform: translateY(-8px) rotate(45deg);
                        transition: transform .3s ease-in-out;
                    }
                }
            }
        }
    }
    &.up{
        top: -80px;
        transition: all .4s ease-in-out;
    }
    &.down{
        height: 60px;
        background-color: $white;
        transition: all .4s ease-in-out;
        &::before{
            content: none;
        }
    }
    nav.navbar{
        background-color: transparent;
        z-index: 2;
        position: relative;
        width: 100%;
        height: 100px;
        top: 0;
        justify-content: unset !important;
        padding-right: 0;
        @media (max-width: 768px){
            height: 60px;
        }
        &.nav-up{
            transition: all .4s ease-in-out;
            top: -80px;
            opacity: 0;
        }
        &.nav-down{
            height: 60px;
            transition: all .4s ease-in-out;
            opacity: 1;
            top: 0;
            z-index: 40;
            .pdf{
                color: $gray_d1 !important;
            }
            .navbar-toggler{
                >div{
                    background-color:  $gray_d1 !important;
                }
            }
        }
        @media (max-width: 768px){
            padding: 20px 0 0 0 !important;
            max-width: 92vw;
        }
        .navbar-brand{
            margin-right: auto;
            margin-left: -10px;
            @include media-breakpoint-down(md){
                margin-left: 0;
            }
            svg{
                width: 173px;
                @media (max-width: 768px){
                    width: 120px;
                }
            }
        }
        .pdf{
            color: $gray_d1;
            font-size: 13px;
            line-height: 14px;
            font-weight: 700;
            margin-right: 70px;
            position: relative;
            @media (max-width: 768px){
                margin-right: 30px;
            }
        }
        button.navbar-toggler{
            border: none;
            padding-right: 0;
            display: inline;
            float: right;
            &:focus{
                outline: none;
            }
            div{
                display: block;
                border-radius: 6px;
                background-color: $gray_d1;
                margin-bottom: 5px;
                height: 2px;
                &:nth-of-type(odd){
                    width: 22px;
                    transition: transform .3s ease-in-out;
                }
                &:nth-of-type(even){
                    width: 16px;
                    transition: width .3s ease-in-out;
                }
            }
        }
    }
    #navbarNav{
        padding-bottom: 200px;
        overflow-y: scroll;
        @media (max-width: 768px){
            padding-bottom: 100px;
        }
        &.show{
            background-color: $body;
            z-index: 1;
            .top-menu-container{
                overflow: scroll;
                -ms-overflow-style: none;
            }
            .top-menu-container::-webkit-scrollbar  {
                display: none;
              }
            .footer-container{
                height: 130px;
                width: 100vw;
                background-color: $font;
                position: absolute;
                bottom: 0;
                color: $white;
                left: 0;
                @media (max-width: 768px){
                    height: 190px;
                }
            }
        }
        &.collapsing{
            transition: none !important;
        }
    }
}