.chapter-content-block {

        .text-col {
            align-items: start !important;
        }
        .page-title {
            font-weight: 700;
            margin-top: 20px;
            color: $grey-2024 !important;
            font-size: 42px;
            font-style: normal;
            text-decoration-line: underline;
            text-transform: uppercase;
            line-height: normal;

            font-size: calc(46px + (56 - 46) * ((100vw - 768px) / (1200 - 768)));

            @media (min-width: 1200px) {
                font-size: 56px;
            }

            @media (max-width: 768px) {
                font-size: 46px;
            }
        }

    .paragraph-block {

        .chapter-overview {
            a {
                text-decoration: none;
                color: $grey-2024;
                border-bottom: 1px solid transparent;
                transition: border-color 0.3s;
                max-width: fit-content;
                font-weight: 700;

                &:hover {
                    color: $grey-2024;
                    border-color: $grey-2024;
                }
            }
        }
    }
}

.page-arstandardindexpage .chapter-container.first .chapter-content-block img.chapter-image {
    margin-top: 50px !important;
}