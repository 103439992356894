.prologue{
    .prologue-container{
        a{
            font-weight: 700;
            color: $gray_d1;
        }
        img{
            position: relative;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            display: block;
            max-width: 75%;
            object-fit: cover;
            position: relative;
            float: unset !important;
            @include media-breakpoint-down(md){
                max-width: 100%;
            }
        }
        .col-12 {
            flex: 0 0 75% !important;
            max-width: 75% !important;
        }
        button.read-more{
            width: 132px;
            height: 50px;
            background-color: transparent;
            border: 2px solid $gray_d1;
            font-size: 13px;
            line-height: 14px;
            color: $gray-d1;
            font-weight: 700;
            border-radius: 2px;
            margin: 40px auto 80px auto;
            @media (max-width: 992px){
                font-size: 12px;
                line-height: 13px;
            }
            @media (max-width: 768px){
                width: 100px;
                height: 40px;
                font-size: 11px;
                line-height: 12px;
                margin: 40px auto 50px auto;
            }
            &.more{
                display: none !important;
            }
        }
        .rich-text{
            line-height: 30px;
            padding-top: 20px;
            @media (max-width: 992px){
                line-height: 27px;
                font-size: 16px;
            }
            @media (max-width: 768px){
                line-height: 23px;
                font-size: 14px;
            }
        }
    }
    &.article-open + .paragraph-block{
        height: 100%;
    }
}
.prologue + .paragraph-block{
    height: 0;
    overflow: hidden;
}