.community-report-block{
    .unit-container{
        margin-top: 40px;
        img{
            width: 100%;
            height: auto;
            @media (max-width: 768px){
                max-width: 200px;
            }
        }
        .text{
            a{
                font-weight: 500;
                color: $gray_d1;
                border-bottom: 1px solid $green;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
}