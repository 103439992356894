.table-block{
    padding: 20px !important;
    margin-bottom: 60px;
    margin-top: 40px;
    .table-col{
        background-color: $body;
        padding: 20px !important;
        @media (max-width: 768px){
            overflow-x: scroll;
        }
    }
    .row{
        @media (max-width: 768px){
            margin: 0 !important;
        }
    }
    .title{
        font-size: 20px;
        line-height: 23px;
        color: $gray_d1;
        font-weight: 700;
    }
    .subtitle{
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 24px;
        color: $gray_d1;
        font-style: italic;
        @media (max-width: 768px){
            font-size: 16px;
            line-height: 22px;
        }
    }
    .table-container{
        padding: 10px 0;
        min-width: 520px;
        padding-right: 20px;
        @media (max-width: 768px){
            padding-right: 20px;
        }
        table.table{
            margin-bottom: 0;
            font-variant-numeric: lining-nums tabular-nums;
            // font-family: 'gotham-narrow' !important;
            font-family: 'Open Sans', sans-serif !important;
            thead{
                font-size: 15px;
                line-height: 16px;
                tr{
                    th{
                        border-bottom: 1px solid $gray_d1;
                        border-top: none;
                        color: $font;
                        font-family: 'Open Sans', sans-serif;
                        &.htCenter{
                            text-align: center;
                        }
                        &.htRight{
                            text-align: right;
                        }
                        &.grey-cell{
                            background-color: $body;
                        }
                        &.white-cell{
                            background-color: $white;
                        }
                        &.red-cell{
                            background-color: $red;
                            color: $white;
                        }
                        &.bt{
                            border-top: 2px solid $font !important;
                        }
                        &.bb{
                            border-bottom: 2px solid $font !important;
                        }
                    }
                }
            }
            tbody{
                font-size: 15px;
                tr{
                    height: 48px;
                    &:first-of-type{
                        td{
                            border: none;
                        }
                    }
                    &:last-of-type{
                        td{
                            border-bottom: 2px solid $gray_d1;
                        }
                    }
                    td{
                        font-family: 'Open Sans', sans-serif;
                        border-top: 1px solid rgba(151,151,151,0.35);
                        @media (max-width: 768px){
                            font-size: 14px;
                        }
                        &.grey-cell{
                            background-color: $body;
                        }
                        &.white-cell{
                            background-color: $white;
                        }
                        &.red-cell{
                            background-color: $red;
                            color: $white;
                        }
                        &.htCenter{
                            text-align: center;
                        }
                        &.htRight{
                            text-align: right;
                        }
                        &.bt{
                            border-top: 2px solid $gray_d1 !important;
                        }
                        &.bb{
                            border-bottom: 2px solid $gray_d1 !important;
                        }
                        &.bold-cell{
                            font-weight: 700;
                            font-family: 'Open Sans', sans-serif;
                            font-size: 15px !important;
                        }
                        &.mergecell{
                            white-space: nowrap;
                        }
                        &.nb{
                            border: none;
                        }
                    }
                }
            }
        }
    }
}
