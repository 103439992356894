.image-link-block{
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    @media (max-width: 992px){
        padding-top: 35px;
        padding-bottom: 35px;
    }
    @media (max-width:768px){
        padding-top: 30px;
        padding-bottom: 30px;
    } 
    .img-col{
        &:nth-of-type(3){
            margin-right: 30px;
            @media (max-width: 992px){
                margin-right: 0;
            }
        }
    }
    img{
        width: 100%;
        max-width: 255px;
        max-height: 245px;
        object-fit: cover;
        margin-bottom: 30px;
        @media (max-width: 992px){
            max-width: 210px;
            max-height: 202px;
        }
        @media (max-width: 768px){
            max-width: 158px;
            max-height: 152px;
            margin-bottom: 20px;
        }
    }
    .chapter{
        position: absolute;
        bottom: 60px;
        right: 3px;
        background-color: #676668;
        padding: 13px 20px;
        color: $white;
        font-size: 13px;
        line-height: 14px;
        font-weight: 700;
        border-radius: 2px;
        @media (max-width: 992px){
            font-size: 12px;
            line-height: 13px;
            padding: 12px 25px;
        }
        @media (max-width: 768px){
            font-size: 11px;
            line-height: 12px;
            padding: 10px 20px;
            bottom: 34px;
        }
        svg{
            margin-left: 10px;   
        }
    }
}