div[class*="-block"] {
    position: relative;
    &>.frontend-edit {
        display: none;
    }
    &:hover {
        &>.frontend-edit {
            display: flex;
            position: absolute;
            right: 100px;
            top: 0;
            z-index: 999;
        }
    }
}

.frontend-edit {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 3.5em;
    height: 3.5em;
    margin: 0!important;
    overflow: hidden;
    background-color: #fff;
    border-radius: 50%;
    color: #000;
    padding: 0!important;
    cursor: pointer;
    box-shadow: 0 0 1px 0 #6bd6e6, 0 1px 10px 0 rgba(107,214,230,.7);
    transition: all .2s ease-in-out;
    font-size: 16px;
    text-decoration: none!important;
    position: relative;
    
    span {
        display: none;
    }

    &:before {
        content: "";
        font-family: wagtail;
        transition: color .2s ease;
        font-size: 32px;
        width: 100%;
        padding-left: 0.4em;
        margin: 0;
        font-family: wagtail;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        speak: none;
        text-decoration: none;
        line-height: 1em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: left;
        vertical-align: middle;
    }
}