.twocol-text-flow{
    .standard-title-block{
        .titleblock{
            flex: 0 0 75% !important;
            max-width: 75% !important;
        }
    }
    .text-flow-container{
        max-width: 960px;
        .circle-image-block{
            max-width: 255px;
            float: right;
            margin-right: 30px;
            @media (max-width: 768px){
                max-width: 158px;
                max-height: 158px;
                .circle-image{
                    max-height: 158px;
                    max-width: 158px;
                    width: 158px;
                    margin-left: 0;
                    @media (max-width: 768px){
                        margin-left: -15px;
                    }
                }
            }
        }
        .info-circle-block{
            float: right;
            max-width: 255px;
            margin-top: 0 0 20px 20px;
            @media (max-width: 768px){
                max-width: 158px;
                max-height: 158px;
                margin: 0 0 20px 20px;
                .info-circle{
                    max-height: 158px;
                    max-width: 158px;
                    width: 158px;
                    margin: 0;
                }
            }
        }
        .paragraph-block{
            max-width: 80%;
            @media (max-width: 992px){
                max-width: 100%;
            }
            @media (max-width: 768px){
                .standard-title-block{
                    .titleblock{
                        min-width: 100vw;
                    }
                }
            }
        }
    }
}