.twocol-6-5{
    align-items: flex-start !important;
    .subtitle-block{
        .subtitle-col{
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
    }
    img{
        max-width: 100%;
        margin-bottom: 40px;
    }
    &.video-block-inside{
        @media (max-width: 992px){
            .left-col{
                order: 2;
            }
            .right-col{
                order: 1;
            }
        }
        .left-col{
            margin-top: -10px;
        }
        .right-col{
            padding-top: 0 !important;
        }
    }
}  