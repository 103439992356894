.circle-image{
    .info-circle{
        .description{
            padding: 0;
        }
    }
    &.large-container{
        @media (max-width: 992px){
            width: 330px;
            height: 330px;
        }
        @media (max-width: 768px){
            width: 345px;
            height: 345px;
            margin-left: 0;
        }
    }
    &:first-of-type{
        display: flex;
        align-items: flex-end;
    }
    &.col_10{
        margin-bottom: 100px;
        margin-top: 40px;
        display: block;
        width: 100%;
        height: 100%;
        @media(max-width: 992px){
            margin-top: 20px;
            margin-bottom: 40px; 
            max-width: 100%;
            max-height: 100%;
            margin-left: 0;
        }
        img{
            width: 100%;
            max-width: 1015px;
            height: auto;
        }
    }
    &.col_9{
        margin-bottom: 100px;
        margin-top: 40px;
        display: block;
        height: auto;
        width: 100%;
        @media(max-width: 992px){
            margin: 20px 0 0 0;
            min-width: 100%;
            max-height: unset;
            max-width: unset;     
        }
        img{
            width: 100%;
            max-width: 922px;
            height: auto;
        }
    }
    &.small{
        max-width: 255px;
        height: 255px;
        @include media-breakpoint-down(md){
            img{
                margin-bottom: 0 !important;
                margin-left: 10px !important;
            }
        }
    }
    img{
        height: 255px;
        width: 255px;
        @media (max-width: 768px){
            height: 158px;
            width: 158px;
        }
        &.make-circle{
            border-radius: 50% !important;
        }
        &.large{
            width: 540px;
            object-fit: cover;
            @media (max-width: 992px){
                max-width: 330px;
                max-height: 330px;
                width: 100%;
                margin-top: 0;
            }
            @media (max-width: 768px){
                max-width: 345px;
                max-height: 345px;
                width: 100%;
                margin-top: 0;
            }
        }
    }
}