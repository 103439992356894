.paragraph-image-block{
    .img-container{
        img{
            width: 100%;
            object-fit: cover;
            max-width: 300px;
            @media (max-width: 768px){
                max-width: 200px;
            }
        }
    }
}