.circle-image-block{
    padding-bottom: 40px;
    padding-bottom: 40px;
    @media (min-width: 768px) and (max-width: 992px){
        margin-left: auto;
    }
    .circle-image{
        height: 540px;
        width: 540px;
        position: relative;
        @media (max-width: 992px){
            max-width: 330px;
            max-height: 330px;
        }
        @media (max-width: 768px){
            margin-left: 15px;
            max-width: 345px;
            max-height: 345px;
        }
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            &.make-circle{
                border-radius: 50%;

            }
        }
        .info-circle{
            border-radius: 100%;
            height: 160px;
            width: 160px;
            position: absolute;
            right: -30px;
            bottom: -60px;
            margin-bottom: 0;
            @media (min-width: 768px) and (max-width: 992px){
                width: 210px;
                height: 210px;
                left: -120px !important;
                bottom: 18% !important;
            }
            &.red{
                background-color: $red;
            }
            &.gold{
                background-color: $gold;
            }
            .first-line{
                font-size: 13px;
                line-height: 14px;
                font-weight: 500;
                color: $white;
            }
            .number{
                font-size: 60px;
                line-height: 66px;
                @media (max-width: 1200px){
                    font-size: 50px;
                    line-height: 56px;
                }
                @media (max-width: 768px){
                    font-size: 40px;
                    line-height: 46px;
                }
            }
            .description{
                font-size: 15px;
                line-height: 17px;
                @media (max-width: 768px){
                    font-size: 14px;
                    line-height: 15px;
                }
            }
            &.circle-true{
                bottom: -1%;
            }
            &.right{
                right: 0%;
            }
            &.left{
                left: 0;
                right: unset;
            }
        }
    }
    .square-image{
        img{
            width: 100%;
        }
        .info-circle{
            width: 200px;
            height: 200px;
            border-radius: 100%;
            position: absolute;
            right: -60px !important;
            bottom: 0;
            margin-bottom: 0;
            @media (min-width: 768px) and (max-width: 992px){
                width: 210px;
                height: 210px;
                left: -120px !important;
            }
            @media (max-width: 767px){
                right: 15px !important;
            }
            &.red{
                background-color: $red;
            }
            &.gold{
                background-color: $gold;
            }
            .first-line{
                font-size: 13px;
                line-height: 14px;
                font-weight: 500;
                color: $white;
            }
            .description{
                font-weight: 700;
                font-size: 15px;
                line-height: 17px;
                @media (max-width: 768px){
                    font-size: 14px;
                    line-height: 15px;
                }
            }
            &.circle-true{
                bottom: -1%;
            }
            &.right{
                right: 0%;
            }
            &.left{
                left: 0;
                right: unset;
            }
        }
    }
}