body{
    /////////////////////// Minor changes for 2021 report ///////////////////////
    &.year-2021{
        color: $font-2021 !important;
        &.page-arhomepage{
            @include media-breakpoint-down(md){
                @media screen and (orientation:landscape) {
                    background-color: $light-blue-2021 !important;
                }
            }
        }
        .background-container .background-image svg.red{
            @media (min-width: 768px) and (max-width: 992px){
                left: -6%;
            }
            @include media-breakpoint-down(sm){
                top: -20px;
            }
        }
        .bottom-menu-container .bottom-menu ul li {
            @include media-breakpoint-down(md){
                background-color: $dark-brown-2021;
            }
        }
        .ar-home-page{
            @media (min-width: 992px) and (max-width: 1200px){
                padding-top: 40px;
            }
            @include media-breakpoint-down(sm){
                padding-top: 70px;
            }
        }
        .side-menu-col{
            @include media-breakpoint-down(sm){
                margin-top: 0;
            }
            ul{
                z-index: 30 !important;
                li{
                    color: $dark-brown-2021;
                    &::after{
                        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxMCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuNzA2MjAxIDIuMTIxMjRMNy43NzcyNyA5LjE5MjMxQzguMTY3NzkgOS41ODI4MyA4LjgwMDk2IDkuNTgyODMgOS4xOTE0OCA5LjE5MjMxQzkuNTgyMDEgOC44MDE3OCA5LjU4MjAxIDguMTY4NjIgOS4xOTE0OCA3Ljc3ODA5TDIuMTIwNDEgMC43MDcwMjZDMS43Mjk4OSAwLjMxNjUwMSAxLjA5NjcyIDAuMzE2NTAyIDAuNzA2MjAxIDAuNzA3MDI2QzAuMzE1Njc2IDEuMDk3NTUgMC4zMTU2NzYgMS43MzA3MiAwLjcwNjIwMSAyLjEyMTI0WiIgZmlsbD0iIzRiNDU0NCIvPgo8cGF0aCBkPSJNMi4xMjIyMiAxNi4xOTIyTDkuMTkzMjggOS4xMjExN0M5LjU4MzgxIDguNzMwNjQgOS41ODM4MSA4LjA5NzQ4IDkuMTkzMjggNy43MDY5NkM4LjgwMjc2IDcuMzE2NDMgOC4xNjk1OSA3LjMxNjQzIDcuNzc5MDcgNy43MDY5NkwwLjcwODAwMyAxNC43NzhDMC4zMTc0NzggMTUuMTY4NSAwLjMxNzQ3OCAxNS44MDE3IDAuNzA4MDAzIDE2LjE5MjJDMS4wOTg1MyAxNi41ODI4IDEuNzMxNjkgMTYuNTgyOCAyLjEyMjIyIDE2LjE5MjJaIiBmaWxsPSIjNGI0NTQ0Ii8+Cjwvc3ZnPgo=");
                    }
                }
            }
        }
        .chapter-container{
            &.gray-bg{
                .chart-block-container{
                    .chart-element{
                        background-color: $light-background-2021 !important;
                    }
                }
                .table-block{
                    .table-col{
                        background-color: $light-background-2021 !important;
                    }
                }
                .twocol-5-5{
                    .table-block{
                        background-color: $light-background-2021 !important;
                    }
                }
            }
        }
        .standard-title-block{
            padding-top: 20px !important;
            margin-top: 0px !important;
            @include media-breakpoint-down(md){
                padding-top: 0 !important;
                
            }
        }
        .prologue {
            .standard-title-block .titleblock .child{
                color: $dark-brown-2021 !important;
            }
            .prologue-container{
                .read-more{
                    background-color: $yellow-2021;
                    border: none;
                    color: $white;
                    height: 40px;
                    text-transform: uppercase;
                    margin: 20px 0 0 0;
                }
            }
            @include media-breakpoint-up(lg){
                &.article-open{
                    img{
                        margin-bottom: 20px;
                    }
                }
                .prologue-container {
                    .outer-container{
                        display: flex;
                        .col-12{
                            flex: 0 0 60% !important;
                            max-width: 60% !important;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                        }
                        img{
                            max-width: 40%;
                            min-height: 400px;
                        }
                    }
                }
            }
        }
        .image-link-block{
            .chapter{
                background-color: $yellow-2021;
            }
        }
        .subtitle-block{
            font-size: 24px;
            line-height: 34px;
        }
        .info-circle .number, .description{
            color: $background-2021 !important;
        }
        .info-circle .description {
            sub{
                font-size: 75% !important;
            }
        }
        &.heimsmarkmid{
            .chapter-container{
                .titleblock{
                    .parent{
                        border-bottom: 2px solid $yellow-2021;
                    }
                }
            }
        }
        &.gri{
            .chapter-container{
                .titleblock{
                    .parent{
                        border-bottom: 2px solid $blue-2021;
                    }
                }
            }
        }
        &.arsreikningur{
            .chapter-container{
                .titleblock{
                    .parent{
                        border-bottom: 2px solid $pastel-pink-2021;
                    }
                }
            }
            .sidemenu-container{
                .current-page{
                    color: $pastel-pink-2021;
                }
            }
        }
        &.sundurlidanir{
            .chapter-container{
                .titleblock{
                    .parent{
                        border-bottom: 2px solid $green-2021;
                    }
                }
            }
            .sidemenu-container{
                .current-page{
                    color: $green-2021;
                }
            }
        }
        &.page-arstandardindexpage, &.page-arstandardpage {
            .chapter-container:not(.first), .chapter-container:not(:last-child) {
                padding-bottom: 60px !important;
                .anchor{
                    padding-bottom: 0 !important;
                }
            }
            .gri-body-container .gri-table tbody tr.sub-heading-row td.gri-sub{
                color: $pastel-pink-2021 !important;
            }
        }
        .focus-text-block .focus-text-container .text{
            color: $dark-brown-2021 !important; 
        }
        .static-image-block{
            margin-bottom: 80px;
            margin-top: 0;
        }
        .link-block, .paragraph-block{
            a{
                color: $dark-brown-2021;
            }
            svg{
                path{
                    &:first-child{
                        stroke: $dark-brown-2021;
                    }
                    &:last-child{
                        fill: $dark-brown-2021;
                    }
                }
            }
        }
        .chart-block-container{
            .subtitle{
                color: $font-2021;
            }
            .title{
                color: $dark-brown-2021;
            }
        }
        .community-report-block{
            padding-bottom: 40px;
        }
        .image-list-block{
            .img-title{
                color: $green-2021 !important;
            }
        }
        .vinbud-arsins-block:nth-of-type(even) .title-container .title{
            background-color: $pastel-pink-2021 !important
        }
        .vinbud-arsins-block .title-container .title{
            background-color: $brown-2021 !important
        }
        .table-block .table-col{
            background-color: $light-background-2021 !important;
            .title{
                color: $font-2021 !important;
            }
        }
        .box-counter-block .small-box, .box-counter-block .large-box{
            background-color: $light-background-2021 !important;
        }
        .two-column-block {
            padding-bottom: 0 !important;
            .chart-block-container, .chart-element{
                background-color: $light-background-2021 !important;
            }
            .chapter-container.gray-bg .table-block .table-col{
                background-color: $light-background-2021 !important;
            }
        }
        &.vidskiptavinir, &.mannaudur, &.birgjar, &.eigandinn, &.samfelagid{
            &.page-arstandardindexpage{
                .chapter-container.first img.chapter-image{
                    @media (min-width:768px) and (max-width: 1200px){
                        min-height: 70vh !important;
                    }
                    @media (max-width: 400px){
                        min-height: 340px !important;
                        width: 120% !important;
                        top: -50px;
                    }
                    @media (max-width: 380px){
                        width: 100% !important;
                        bottom: -99vh !important;
                    }
                }
            }
            .first{
                &.white-2021{
                    color: $white !important;
                    .text-col{
                        .parent, .child{
                            color: $white !important;
                        }
                        .parent{
                            margin-bottom: 60px;
                            position: relative;
                            &::after{
                                background-color: $white;
                                bottom: -4px;
                                content: '';
                                left: 0;
                                height: 1px;
                                position: absolute;
                                width: 140px;
                            }
                            @include media-breakpoint-down(md){
                                margin-bottom: 0;
                            }
                        }
                        p{
                            position: relative;
                            &::after{
                                background-color: $white;
                                bottom: -26px;
                                content: '';
                                left: 0;
                                height: 1px;
                                position: absolute;
                                width: 140px;
                                @include media-breakpoint-down(md){
                                    content: none;
                                }
                            }
                        }
                    }
                }
            }
            .second{
                padding-top: 60px;
            }
        }
        .sidemenu-container{
            .current-page{
                font-weight: 700 !important;
            }
            ul{
                li{
                    &.active{
                        &::before{
                            background-color: $peach-2021;
                        }
                    }
                    a{
                        color: $dark-brown-2021;
                        font-weight: 700;
                    }
                }
            }
        }
        .sidetext{
            color: $white !important;
            font-family: 'Gotham-thin' !important;
            font-size: 100px !important;
            font-weight: 600 !important;
            letter-spacing: 10px; 
        }
        .chapter-container{
            background-color: $background-2021 !important;
            .titleblock{
                .parent{
                    color: $dark-brown-2021 !important;
                    display: inline-block;
                    padding-bottom: 0;
                    font-weight: 700;
                }
                .child{
                    font-weight: 400;
                    margin-top: 20px;
                }
            }
        }
        &.arsreikningur, &.sundurlidanir, &.heimsmarkmid, &.gri{
            .chapter-container{
                .titleblock{
                    .child{
                        color: $dark-brown-2021 !important;
                    }
                }
            }
        } 
        &.vidskiptavinir{
            .sidemenu-container{
                .current-page{
                    color: $pastel-pink-2021 !important;
                }
            }
            .chapter-container{
                .titleblock{
                    .parent{
                        border-bottom: 2px solid $pastel-pink-2021;
                    }
                    .child{
                        color: $pastel-pink-2021;
                    }
                }
            }
        }
        &.mannaudur{
            .sidemenu-container{
                .current-page{
                    color: $green-2021 !important;
                }
            }
            .chapter-container{
                .titleblock{
                    .parent{
                        border-bottom: 2px solid $green-2021;
                    }
                    .child{
                        color: $green-2021;
                    }
                }
            }
        }
        &.samfelagid{
            .sidemenu-container{
                .current-page{
                    color: $blue-2021 !important;
                }
            }
            .chapter-container{
                .titleblock{
                    .parent{
                        border-bottom: 2px solid $blue-2021;
                    }
                    .child{
                        color: $blue-2021;
                    }
                }
            }
        }
        &.birgjar{
            .sidemenu-container{
                .current-page{
                    color: $yellow-2021 !important;
                }
            }
            .chapter-container{
                .titleblock{
                    .parent{
                        border-bottom: 2px solid $yellow-2021;
                    }
                    .child{
                        color: $yellow-2021;
                    }
                }
            }
        }
        &.eigandinn{
            .sidemenu-container{
                .current-page{
                    color: $brown-2021 !important;
                }
            }
            .chapter-container{
                .titleblock{
                    .parent{
                        border-bottom: 2px solid $brown-2021;
                    }
                    .child{
                        color: $brown-2021;
                    }
                }
            }
        }
        &.inngangur{
            .sidemenu-container{
                .current-page{
                    color: $light-blue-2021 !important;
                }
            }
            .chapter-container{
                .titleblock{
                    .parent{
                        border-bottom: 2px solid $light-blue-2021;
                    }
                    .child{
                        color: $dark-brown-2021;
                    }
                }
            }
        }
    }
}