.image-list-block{
    padding-bottom: 40px;
    @media (max-width: 768px){
        padding-bottom: 0;
    }
    .img-col{
        // flex: 0 0 33.33333% !important;
        // max-width: 33.33333% !important;
        @media (max-width: 768px){
            // flex: 0 0 50% !important;
            // max-width: 50% !important;
            &.margin{
                margin-top: 0;
            }
        }
        @media (max-width: 540px){
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
    }
    .make-circle{
        img{
            height: 255px;
            width: 255px;
            clip-path: circle(at center);
            @media (max-width: 1200px){
                height: 215px;
                width: 215px;
            }
            @media (max-width: 768px){
                height: 158px;
                width: 158px;
            }
        }
    }
    img{
        height: auto;
        width: 100%;
        object-fit: cover;
        margin-bottom: 20px;
        margin-top: 40px;
    }
    .img-title{
        color: $gold;
        font-weight: 700;
    }
    .img-title, .img-subtitle{
        font-size: 14px;
        line-height: 23px;
        @media (max-width: 992px){
            line-height: 27px;
            font-size: 16px;
        }
        @media (max-width: 768px){
            line-height: 23px;
            font-size: 14px;
        }
    }
}