.circles{
    span{
        width: 550px;
        height: 550px;
        display: block;
        border: 1px solid $blue-50;
        border-radius: 50%;
        position: absolute;
        &:last-child{
            margin-top: 100px;
            border: 1px solid $gold-50;
        }
    }
    position: absolute;
    z-index: 1;
    height: 650px;
    width: 550px;
    transform-origin: center;
    &.left{
        left: -350px;
    }
    &.right{
        right: -150px;
    }
}