body {

    /////////////////////// Minor changes for 2023 report ///////////////////////
    &.year-2023 {
        color: $font-2023 !important;

        header {
            .top-menu-container {
                .top-menu {
                    &.color-green {
                        .menu-title {
                            color: $font-secondary-2023;
                        }

                        .line {
                            background-color: $font-secondary-2023;
                        }
                    }

                    .menu-title {
                        color: $font-secondary-2023;
                    }

                    .line {
                        background-color: $font-secondary-2023;
                    }
                }

                .top-ul-container {
                    a {
                        &:hover {
                            border-color: $font-secondary-2023;
                        }
                    }
                }
            }

            .nav-container nav.navbar button.navbar-toggler:focus,
            :active {
                outline: none !important;
            }

            .nav-container {
                .navbar {
                    .navbar-brand {
                        img {
                            width: 300px;

                            @include media-breakpoint-down(md) {
                                width: 200px;
                            }
                        }
                    }
                }
            }
        }


        &.page-arhomepage {
            @include media-breakpoint-down(md) {
                @media screen and (orientation:landscape) {
                    background-color: $green-bg-2023 !important;
                    padding-top: 30px;
                }
            }

            .ar-home-page .title {
                color: $font-secondary-2023;
                font-weight: 700;

                font-size: calc(30px + (48 - 30) * ((100vw - 768px) / (1200 - 768)));
                line-height: calc(32px + (50 - 32) * ((100vw - 768px) / (1200 - 768)));

                @media (min-width: 1200px) {
                    font-size: 48px;
                    line-height: 50px;
                }

                @media (max-width: 768px) {
                    font-size: 32px;
                    line-height: 32px;
                }
            }

            .year-background {
                img {
                    position: fixed;
                    max-width: 65%;
                    width: 65%;
                    height: 1017px;
                    bottom: -321px;
                    right: -8%;
                    transition: all .3s ease-in-out;

                    @media (max-width: 1626px) {
                        right: -5%;
                    }

                    @media (max-width: 1200px) {
                        width: 58%;
                    }

                }
            }
        }

        &:not(.inngangur) {
            .chapter-container.first .chapter-content::after {
                background-color: $green-bg-2023;
                height: 130vh;

                @include media-breakpoint-down(md) {
                    height: 55vh;

                }

                @media (min-width: 1200px) {
                    @media screen and (orientation:portrait) {
                        width: 24vw;
                    }
                }
            }
        }

        &.inngangur:not(.arsreikningur):not(.sundurlidanir):not(.gri):not(.heimsmarkmid) {
            .chapter-container.first {
                @media (min-width: 992px) {
                    .right-col {
                        flex: 0 0 41.66667% !important;
                        max-width: 41.66667% !important;
                    }
                }

                @include media-breakpoint-down(md) {
                    .twocol_7_5 {
                        .left-col {
                            order: 2;
                        }

                        .right-col {
                            order: 1;
                        }
                    }
                }

                .article-2022-open {
                    height: 100%;

                    .container.two-column-block,
                    .container.paragraph-block {
                        height: 100% !important;
                    }
                }

                &.inngangur {
                    .chapter-content {

                        .container.two-column-block,
                        .container.paragraph-block {
                            height: 0;
                            overflow: hidden;
                        }

                        .two-column-block {
                            img {
                                width: 100%;
                            }

                            .focus-text-block {
                                .focus-text-container {
                                    .text {
                                        padding: 0 40px 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .background-container .background-image {
            background-color: $green-bg-2023;

            &::after {
                background-color: $green-bg-2023 !important;
            }

            svg.red {
                path {
                    fill: $green-bg-2023 !important;
                }
            }
        }

        .bottom-menu-container {
            .bottom-menu {
                background-color: $menu-bg-2023;
                padding-left: 0;

                @include media-breakpoint-down(lg) {
                    padding-left: 15px !important;
                    padding-right: 15px !important;
                }

                ul li {
                    background-color: $menu-bg-2023;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .bottom-menu-btns {
                &.prev-page-container {
                    background-color: $green-2023-l3;

                    a {
                        color: $green-2023;

                        svg path {
                            stroke: $green-2023;

                        }
                    }
                }

                &.next-page-container {
                    background-color: $green-2023-l2;

                    a {
                        color: $green-2023;

                        svg path {
                            stroke: $green-2023;

                        }
                    }
                }
            }
        }

        .ar-home-page {
            @media (min-width: 992px) and (max-width: 1200px) {
                padding-top: 40px;
            }

            @include media-breakpoint-down(sm) {
                padding-top: 70px;
            }
        }

        .side-menu-col {
            @include media-breakpoint-down(sm) {
                margin-top: 0;
            }

            .col-12 {
                @include media-breakpoint-up(xl) {
                    flex: 0 0 140% !important;
                    max-width: 140% !important;
                }
            }

            ul.side-menu {
                position: relative;
                z-index: 30 !important;

                @include media-breakpoint-down(sm) {
                    min-width: calc(100vw - 30px);
                }

                li {
                    color: $font-secondary-2023;
                    font-weight: 700;

                    &::after {
                        right: 50%;
                        background-image: url('../img/arrow.svg');
                    }

                    &.open {
                        // color: $green-2023;

                        &::after {
                            content: none;
                        }

                        .line {
                            background-color: $burgundy-2023;
                            border-color: $burgundy-2023;
                        }

                        ul.sub-menu {
                            background-color: $submenu-bg-2023;
                            right: 0;
                            top: 0;

                            li.chapters {
                                a {
                                    color: $font-secondary-2023 !important;
                                    text-decoration: none;

                                    &:hover {
                                        border-bottom: 2px solid $submenu-underline-2023;
                                    }
                                }
                            }
                        }
                    }

                    &.grey {
                        opacity: .7;

                        &::after {
                            content: none;
                        }
                    }
                }
            }
        }

        .chapter-container {
            background-color: $light-bg-2023;

            .chart-block-container {
                .chart-element {
                    background-color: $white !important;

                    .bottom-label-container {
                        flex-wrap: wrap;

                        .inner {
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }

        .standard-title-block {
            padding-top: 20px !important;
            margin-top: 0px !important;

            @include media-breakpoint-down(md) {
                padding-top: 0 !important;

            }
        }

        .prologue {
            .prologue-container {
                .read-more {
                    background-color: $green-2023-l2;
                    border: none;
                    color: $white;
                    height: 40px;
                    text-transform: uppercase;
                    margin: 20px 0 0 0;
                }
            }

            @include media-breakpoint-up(lg) {
                &.article-open {
                    img {
                        margin-bottom: 20px;
                    }
                }

                .prologue-container {
                    .outer-container {
                        display: flex;

                        .col-12 {
                            flex: 0 0 60% !important;
                            max-width: 60% !important;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                        }

                        img {
                            // max-width: 40%;
                            min-height: 400px;
                        }
                    }
                }
            }
        }

        .link-block,
        .paragraph-block {
            a {
                color: $font-secondary-2023;
                border-bottom: 1px solid $font-secondary-2023;
                text-decoration: none;

                &:hover {
                    color: $link-hover;
                    border-bottom: 1px solid $link-hover;
                }
            }

            svg {
                path {
                    &:first-child {
                        stroke: $burgundy-2023;
                    }

                    &:last-child {
                        fill: $burgundy-2023;
                    }
                }
            }
        }

        .image-link-block {
            .chapter {
                background-color: $green-2023-l2;
            }
        }

        .subtitle-block {
            font-size: 18px;
            line-height: 27px;
            font-weight: 500;
        }

        .info-circle .number,
        .description {
            color: $light-bg-2023 !important;
        }

        .info-circle .description {
            sub {
                font-size: 75% !important;
            }
        }

        .focus-text-block .focus-text-container .text {
            color: $dark-brown-2021;
        }

        &.page-arstandardindexpage,
        &.page-arstandardpage {

            @include media-breakpoint-down(lg) {
                &:not(.inngangur) {
                    .chapter-container.first {
                        background-color: $light-bg-2023;
                    }
                }
            }

            .chapter-container.first {
                .circle-image.large-container {
                    background-color: $green-bg-2023;

                    @media (min-width: 992px) {
                        height: 102vh;
                        margin-left: -20vw;
                        min-height: 1000px;
                        min-width: 80vw;
                    }

                    .chapter-image {
                        z-index: 0;

                        @media(max-width: 1220px) {
                            object-position: right;
                        }

                        @media(max-width: 992px) {
                            left: unset;
                            right: 0;
                        }
                    }

                    svg.circle-bg {
                        left: 0;
                        min-height: 102%;
                        z-index: 1;

                        @media(max-width: 992px) {
                            display: none !important;
                        }

                        path {
                            fill: $green-bg-2023
                        }
                    }
                }
            }

            .chapter-container:not(.first),
            .chapter-container:not(:last-child) {
                padding-bottom: 60px !important;

                .anchor {
                    padding-bottom: 0 !important;
                }
            }

            .gri-body-container {
                .col-12 {
                    background-color: $white;
                }

                .gri-table tbody {
                    tr.sub-heading-row td.gri-sub {
                        color: $moss-green-2023;
                    }

                    tr:nth-child(odd) {
                        background-color: $light-bg-2023;
                    }
                }
            }
        }

        .circle-image-block {
            .square-image {
                .info-circle {
                    &.right {
                        @include media-breakpoint-up(md) {
                            right: -25px !important;
                        }

                        @include media-breakpoint-down(md) {
                            right: 15px !important;
                        }
                    }
                }
            }

            @include media-breakpoint-between(sm, md) {
                margin: 0;

                .square-image {
                    .info-circle {
                        left: unset !important;
                    }
                }
            }

            .color-background {
                .circle-image {
                    position: relative;

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        background-color: $green-2023-l2;
                        width: 100%;
                        max-width: 922px;
                        height: 100%;
                        top: 0;
                        transform: translate(40px, -40px);
                        z-index: -1;

                        @include media-breakpoint-down(md) {
                            content: none;
                        }
                    }

                    img {
                        width: 100%;
                    }
                }

                &.left {
                    .circle-image {
                        &::before {
                            transform: translate(-40px, -40px);

                        }
                    }
                }

                &.make-circle {
                    .circle-image {
                        &::before {
                            border-radius: 50%;
                            transform: translate(21px, -8px);


                        }
                    }
                }
            }
        }

        .static-image-block {
            margin-bottom: 80px;
            margin-top: 0;

            .color-background {
                .static-img {
                    position: relative;

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        background-color: $green-2023-l2;
                        width: 100%;
                        max-width: 922px;
                        height: 100%;
                        top: 0;
                        transform: translate(40px, -40px);
                        z-index: -1;

                        @include media-breakpoint-down(md) {
                            content: none;
                        }
                    }

                    img {
                        width: 100%;
                    }
                }

                &.left {
                    .static-img {
                        &::before {
                            transform: translate(-40px, -40px);
                        }
                    }
                }
            }
        }

        .community-report-block {
            padding-bottom: 40px;

            .unit-container {
                .text a {
                    color: $font-secondary-2023;
                    border-bottom: 1px solid $font-secondary-2023;

                    &:hover {
                        color: $link-hover;
                        border-bottom: 1px solid $link-hover;

                    }
                }
            }
        }

        .image-list-block {
            .img-title {
                color: $moss-green-2023 !important;
            }
        }

        .vinbud-arsins-block {
            .title-container .title {
                background-color: $green-2023-l2 !important;

                .rich-text p {
                    color: $light-bg-2023 !important;
                }

            }

            .store-name {
                color: $burgundy-2023;
            }
        }

        .vinbud-arsins-block:nth-last-child(2) {
            margin-top: 0px;
        }

        .table-block .table-col {
            background-color: $white !important;

            .title {
                color: $grey-2023 !important;
            }

            table {
                tr {
                    td {
                        &.grey-cell {
                            background-color: $light-bg-2023 !important;
                        }
                    }
                }
            }
        }

        .box-counter-block .small-box,
        .box-counter-block .large-box {
            background-color: $white !important;

            &.green {
                background-color: $green-2023 !important;

                .number,
                .line-under,
                .line-over {
                    color: $white !important;
                }
            }

            .line-over {
                padding-top: 20px;
            }

            .line-under {
                padding-bottom: 20px;
            }
        }

        .two-column-block {
            padding-bottom: 0 !important;

            .right-col {
                flex-direction: column;
            }

            img {
                max-width: 100%;
            }

            .chart-block-container,
            .chart-element {
                background-color: $white !important;
            }

            .chapter-container.gray-bg .table-block .table-col {
                background-color: $white !important;
            }

            .poster-block {
                @include media-breakpoint-down(lg) {
                    margin-bottom: 0;
                    margin-top: 0;

                    img {
                        width: auto;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                padding: 20px 0 0 !important;
            }
        }

        &.vidskiptavinir,
        &.mannaudur,
        &.birgjar,
        &.eigandinn,
        &.samfelagid {
            &.page-arstandardindexpage {
                .chapter-container.first img.chapter-image {
                    @media (min-width:540px) and (max-width: 1200px) {
                        min-height: 70vh !important;
                    }

                    @media (max-width: 400px) {
                        min-height: 340px !important;
                        width: 120% !important;
                        top: -50px;
                    }

                    @media (max-width: 380px) {
                        width: 100% !important;
                        bottom: -99vh !important;
                    }
                }
            }

            .first {
                p {
                    color: $font-secondary-2023;
                }
            }

            .second {
                padding-top: 60px;
            }
        }

        .sidemenu-container {
            .current-page {
                font-weight: 700 !important;
            }

            ul {
                li {
                    &::before {
                        background-color: $secondary-green-2023-l2;
                    }

                    &.active {
                        &::before {
                            background-color: $font-secondary-2023;
                        }
                    }

                    a {
                        font-weight: 700;
                        color: $font-secondary-2023;
                        text-decoration: none;
                    }
                }
            }
        }

        .sidetext {
            color: $secondary-green-2023-l2 !important;
            font-family: 'Gotham-thin' !important;
            font-size: 90px !important;
            font-weight: 500 !important;
            letter-spacing: 8px;
        }

        .chapter-container {
            .titleblock {
                .parent {
                    border-bottom: 2px solid $font-secondary-2023;
                    color: $grey-2023;
                    display: inline-block;
                    padding-bottom: 0;
                    font-weight: 700;
                    color: $font-secondary-2023;
                }

                .child {
                    font-weight: 700;
                    margin-top: 20px;
                    color: $font-secondary-2023 !important;

                    font-size: calc(30px + (48 - 30) * ((100vw - 768px) / (1200 - 768)));
                    line-height: calc(32px + (50 - 32) * ((100vw - 768px) / (1200 - 768)));

                    @media (min-width: 1200px) {
                        font-size: 48px;
                        line-height: 50px;
                    }

                    @media (max-width: 768px) {
                        font-size: 32px;
                        line-height: 32px;
                    }
                }
            }
        }

        .sidemenu-container {
            .current-page {
                color: $font-secondary-2023 !important;
            }
        }

        &.inngangur {
            .sidemenu-container {
                .menu-items {
                    color: $grey-2023;

                    .sub-menu {
                        .chapters {
                            a {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }

        .sdg-block {
            .svg-row {
                .svg-container {
                    display: none;

                }
            }
        }
    }

    .focus-text-block-2023 {
        align-items: center;
        height: 100%;
        margin-bottom: 60px;

        .focus-text-container {
            .text {
                color: $green_2023_l1;
                padding: 40px 30px;
                font-weight: 500;

                p {
                    font-size: 32px;
                    line-height: 40px;

                    @media (max-width: 992px) {
                        font-size: 28px;
                        line-height: 35px;
                    }

                    @media (max-width: 768px) {
                        font-size: 24px;
                        line-height: 30px;
                    }
                }

                @media (max-width: 992px) {
                    max-width: 450px;
                    margin-top: 60px;
                }

                @media (max-width: 768px) {
                    margin-top: 40px;
                    max-width: 100%;
                }
            }
        }
    }
}

.gri-importance-block {
    padding: 20px !important;
    margin-bottom: 60px;
    margin-top: 40px;

    .table-container {
        background-color: $white;
        padding: 0 15px 20px 15px;

        table {
            thead {
                color: $moss-green-2023;

                tr {
                    height: 60px;

                    th {
                        font-size: 18px;
                        line-height: 20px;
                        font-weight: 700;
                        border: none;

                        @media (max-width: 768px) {
                            font-size: 16px;
                        }
                    }
                }
            }

            tbody {
                font-size: 15px;

                @media (max-width: 768px) {
                    font-size: 14px;
                }

                tr {
                    height: 46px;

                    &:nth-child(even) {
                        background-color: $light-bg-2023;
                    }

                    @media (max-width: 768px) {
                        height: auto;
                        background-color: transparent !important;
                    }

                    td {
                        border-bottom: 1px solid $font;
                        border-top: 1px solid $font;

                        &.importance-gri-reference {
                            a {
                                color: $font-secondary-2023;
                                text-decoration: none;
                                font-weight: 500;
                                white-space: nowrap;

                                &:hover {
                                    color: $link-hover;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}