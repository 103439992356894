// Ekki búið að láta virka
@font-face {
    font-family: 'aaux';
    src: url('../fonts/aauxpro-bold-webfont.woff2') format('woff2'),
         url('../fonts/aauxpro-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'aaux';
    src: url('../fonts/aauxpro-light-webfont.woff2') format('woff2'),
         url('../fonts/aauxpro-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'aaux';
    src: url('../fonts/aauxpro-medium-webfont.woff2') format('woff2'),
         url('../fonts/aauxpro-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'aaux';
    src: url('../fonts/aauxpro-regular-webfont.woff2') format('woff2'),
         url('../fonts/aauxpro-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}


@function calculateRem($size) {
    $remSize: $size / 10px;
    @return $remSize * 1rem;
}

@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

@mixin line-height($size) {
    line-height: $size;
    line-height: calculateRem($size);
}

@mixin font-styles($font-size, $line-height, $letter-spacing, $font-style, $font-weight, $font-family) {
    @include font-size($font-size);
    @include line-height($line-height);
    letter-spacing: $letter-spacing;
    font-style: $font-style;
    font-weight: $font-weight;
    font-family: $font-family;
}
