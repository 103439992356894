.vinbud-arsins-block{
    margin-top: 70px;
    margin-bottom: 20px;
    &:last-child{
        margin-top: 0;
        margin-bottom: 70px;
    }
    .vinbud-arsins-row{
        @media (min-width: 768px){
            &:nth-of-type(even){
                .image-container{
                    order: 2;

                }
            }
        }
    }
    .title-container, .store-container{
        flex: 0 0 45% !important;
        max-width: 45% !important;
        @include media-breakpoint-down(md){
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
    }
    .title-container{
        @include media-breakpoint-up(lg){
            padding-right: 0;
        }
        .title{
            padding-top: 40px;
            padding-bottom: 40px;
            height: 100%;
            background-color: $gold-80;
            color: $white;
            text-align: center;
            @include media-breakpoint-down(lg){
                padding-top: 80px;
                padding-bottom: 80px;
            }
            .rich-text{
                p{
                    font-size: 32px !important;
                    line-height: 30px !important;
                    font-weight: 700;
                    @include media-breakpoint-down(lg){
                        font-size: 28px !important;
                        line-height: 28px !important;
                    }
                }
            }
            b{
                font-weight: 700;
            }
        }
    }
    .store-container{
        @include media-breakpoint-up(lg){
            padding-left: 0;
        }
        .store-name{
            margin-top: 20px;
            font-weight: 500;
            font-size: 22px;
            text-align: center;
            position: absolute;
            left: -50%;
            transform: translateX(-50%);
            top: 63%;
            color: $white;
            @include media-breakpoint-down(md){
                top: -19%;
                left: 50%;
                font-size: 18px;
            }
            @include media-breakpoint-down(sm){
                top: -90px;
            }
        }
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    &:nth-of-type(even){
        .title-container{
            .title{
                background-color: $blue-80;
            }
        }
    }
}