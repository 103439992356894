.two-column-block{
    padding-top: 20px;
    padding-bottom: 60px;
    &:first-child{
        .right-col{
            align-items: flex-start !important;
            padding-top: 104px;
            .paragraph-block{
                align-self: flex-start;
                margin-top: 0;
            }
        }
        .titleblock, .subtitle-col{
            padding-left: 0;
        }
    }
    .chart-block-container{
        background-color: $white;
        margin: 0 0 40px 0;
        .chart-element{
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
    }
    .table-block{
        padding-left: 0;
        margin-bottom: 0;
        .table-col{
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
    }
    .paragraph-block{
        h3{
            font-weight: 400 !important;
        }
    }
    img{
        height: auto;
        margin-bottom: 40px;
    }
    .subtitle-block{
        .subtitle-col{
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
    }
}