.link-block{
    a{
        font-size: 15px;
        line-height: 17px;
        color: $red;
        margin-right: 4px;
        font-weight: 700;
        @media (max-width: 992px){
            font-size: 14px;
            line-height: 15px;
        }
        @media (max-width: 768px){
            font-size: 12px;
            line-height: 13px;
        }
    }
    svg{
        width: 16px;
        height: 9px;
    }
}