.svg-block{
    padding-top: 100px;
    padding-bottom: 100px;
    @media (max-width: 768px){
        padding-bottom: 30px;
        padding-top: 30px;
    }
    .col-12{
        &.sdg{
            max-height: 350px;
            max-width: 920px;
        }
        img{
            width: 100%;
            height: auto;
        }
    }
    &.medium-svg{
        padding-top: 30px;
        .col-12{
            &.sdg{
                max-height: 760px;
                max-width: 690px
            }
        }
    }
    &.large-svg{
        .col-12{
            &.sdg{
                max-height: 760px;
                max-width: 760px;
            }
        }
    }
}