.box-counter-block{
    padding-top: 20px;
    padding-bottom: 100px;
    @media (max-width: 768px){
        padding-bottom: 20px;
    }
    .number{
        font-size: 70px;
        line-height: 76px;
        font-weight: 700;
        padding: 10px 0;
        @media (max-width: 992px){
            font-size: 50px;
            line-height: 56px;
        }
        @media (max-width: 768px){
            font-size: 38px;
            line-height: 42px;
        }
    }
    .line-over, .line-under, .number{
        &.gold{
            color: $gold;
        }
        &.gold-80{
            color: $gold-80;
        }
        &.red{
            color: $red;
        }
        &.red-80{
            color: $red-80;
        }
        &.blue{
            color: $blue;
        }
        &.blue-80{
            color: $blue-80;
        }
        &.brown{
            color: $brown;
        }
        &.brown-80{
            color: $brown-80;
        }
        &.brons-2020{
            color: $brons-2020;
        }
        &.brons-2020-80{
            color: $brons-2020-80;
        }
        &.blue-2021{
            color: $light-blue-2021;
        }
        &.dark-blue-2021{
            color: $blue-2021;
        }
        &.pink-2021{
            color: $pastel-pink-2021;
        }
        &.brown-2021{
            color: $brown-2021;
        }
        &.yellow-2021{
            color: $yellow-2021;
        }
        &.green-2021{
            color: $green-2021;
        }
        &.yellow-2022{
            color: $yellow-2022;
        }
        &.green-2022{
            color: $green-2022;
        }
        &.light-green-2022{
            color: $light-green-2022;
        }
        &.moss-green-2022{
            color: $moss-green-2022;
        }
        &.gray-2022{
            color: $gray-2022;
        }
        &.brown-2022{
            color: $brown-2022;
        }
        &.secondary-gray-2022{
            background-color: $secondary-gray-2022;
        }
        &.secondary-green-2022{
            background-color: $secondary-green-2022;
        }
        &.secondary-yellow-2022{
            background-color: $secondary-yellow-2022;
        }
        &.secondary-orange-2022{
            background-color: $secondary-orange-2022;
        }
        &.secondary-brown-2022{
            background-color: $secondary-brown-2022;
        }
        &.secondary-red-2022{
            background-color: $secondary-red-2022;
        }
        &.yellow-2023{
            color: $yellow-2023;
        }
        &.green-2023{
            color: $green-2023;
        }
        &.green-2023-l2{
            color: $green-2023-l2;
        }
        &.moss-green-2023{
            color: $moss-green-2023;
        }
        .green-2023-bright {
            color: $green-2023-bright;
        }
        &.grey-2023{
            color: $grey-2023;
        }
        &.brown-2023{
            color: $brown-2023;
        }
        &.burgundy-2023{
            color: $burgundy-2023;
        }
        &.secondary-green-2023-d2{
            color: $secondary-green-2023-d2;
        }
        &.secondary-yellow-2023-d{
            color: $secondary-yellow-2023-d;
        }
        &.secondary-yellow-2023-l{
            color: $secondary-yellow-2023-l;
        }
        &.secondary-blue-2023{
            color: $secondary-blue-2023;
        }
        &.red-2024 {
            color: $red-2024;
        }
        &.red-l1-2024 {
            color: $red-l1-2024;
        }
        &.red-l2-2024 {
            color: $red-l2-2024;
        }
        &.yellow-2024 {
            color: $yellow-2024;
        }
        &.grey-2024 {
            color: $grey-2024;
        }

    }
    .line-over, .line-under{
        line-height: 24px;
        font-weight: 700;
        @media (max-width: 992px){
            line-height: 22px;
        }
        @media (max-width: 768px){
            font-size: 14px;
            line-height: 17px;
        }
    }
    .small-box, .large-box{
        padding-top: 40px;
        padding-bottom: 40px;
        max-width: 255px;
        text-align: center;
        margin-right: 15px;
        margin-left: 15px;
        background-color: $body;
        @media (max-width: 768px){
            margin-bottom: 28px;
        }
        // &:first-of-type{
        //     .number{
        //         color: $green;
        //     }
        // }
        // &:last-of-type{
        //     .line-over, .line-under{
        //         color: $green;
        //     }
        // }
        &.green{
            background-color: $blue-80 !important;
            .number, .line-under, .line-over{
                color: $white !important;
            }
        }
    }
    .small-box{
        @media (max-width: 992px){
            padding-top: 25px;
            max-width: 210px;
        }
        @media (max-width: 768px){
            margin-right: 0;
            margin-left: 0;
            max-width: 100%;
        }
    }
    .large-box{
        padding-top: 30px;
        padding-bottom: 40px;
        max-width: 445px;
        width: 100%;
        max-height: 173px;
        height: 100%;

        @media (max-width: 768px){
            margin-right: 0;
            margin-left: 0;
        }
    }
}