.stat-circle-block{
    &:first-of-type{
        display: flex;
        align-items: flex-end;
        @media (max-width: 768px){
            margin-left: -15px;
            margin-right: 15px;
        }
    }
    &:last-of-type{
        @media (min-width: 992px){
            max-height: 400px;
            align-items: flex-end;
            display: flex;
        }
        @media (max-width: 768px){
            margin-left: 15px;
            margin-right: -15px;
        }
    }
    @media (max-width: 992px){
        margin-top: 60px;
    }
    .stat-circle{
        height: 255px;
        width: 255px;
        border-radius: 100%;
        position: relative;
        overflow: hidden;
        &.green{
            background-color: $green;
        }
        &.red{
            background-color: $red;
        }
        &.gold{
            background-color: $gold;
        }
        @media(max-width: 992px){
            width: 210px;
            height: 210px;
        }
        @media(max-width: 768px){
            width: 160px;
            height: 160px;
        }
        .row{
            z-index: 1;
        }
        .percent{
            font-size: 32px;
            line-height: 40px;
            font-weight: 700;
            color: $white;
            position: relative;
            @media (max-width: 992px){
                font-size: 28px;
                line-height: 32px;
            }
            @media (max-width: 768px){
                font-size: 24px;
                line-height: 30px;
            }
            &.kvk{
                margin-right: 42px; 
                &:after{
                    content: 'KVK';
                    font-size: 13px;
                    color: $white;
                    position: absolute;
                    left: 26%;
                    bottom: -70%;
                }
                @media (max-width: 992px){
                    margin-right: 30px;
                }
                @media (max-width: 768px){
                    margin-right: 20px;
                }
            }
            &.kk{
                &:after{
                    content: 'KK';
                    font-size: 13px;
                    color: $white;
                    position: absolute;
                    left: 26%;
                    bottom: -70%;
                }
            }
        }
        .description{
            line-height: 24px;
            margin-top: 36px;
            font-weight: 500;
            color: $white;
            max-width: 139px;
            text-align: center;
            @media (max-width: 992px){
                font-size: 16px;
                line-height: 20px;
                margin-top: 28px;
                max-width: 125px;
            }
            @media (max-width: 768px){
                font-size: 11px;
                line-height: 12px;
                max-width: 85px;
            }
        }
        .fill{
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            &.gold{
                background-color: $gold_l1;
            }
            &.green{
                background-color: $green_l2;
            }
        }
    }
}