.twocol-5-6{
    .right-col{
        @media (max-width: 992px){
            justify-content: flex-end;
        }
        @media (max-width: 768px){
            justify-content: center;
        }
    }
    img{
        max-width: 100%;
        margin-bottom: 40px;
    }
    .circle-image{
        &.large-container{
            height: 100%;
            width: 100%;
            .large{
                margin-top: 0;
                @media (min-width: 768px) and (max-width: 992px){
                    margin-left: auto;
                }
            }
        }
    }
}