.sdg-block{
    margin-bottom: 30px;
    .svg-row{
        margin-top: 100px;
        margin-bottom: 30px;
        display: flex;
        flex-wrap: wrap;
        svg{
            @media (max-width: 992px){
                width: 91px;
                height: 100px;
            }
            @media (max-width: 768px){
                width: 156px;
                height: 172px;
            }
            &.nr_3{
                path{
                    &.nr_3{
                        fill: $sdg3;
                    }
                }
            }
            &.nr_5{
                path{
                    &.nr_5{
                        fill: $sdg5;
                    }
                }
            }
            &.nr_8{
                path{
                    &.nr_8{
                        fill: $sdg8;
                    }
                }
            }
            &.nr_12{
                path{
                    &.nr_12{
                        fill: $sdg12;
                    }
                }
            }
            &.nr_13{
                path{
                    &.nr_13{
                        fill: $sdg13;
                    }
                }
            }
            &.nr_17{
                path{
                    &.nr_17{
                        fill: $sdg17;
                    }
                }
            }
        }
    }
    .sdg-title{
        font-weight: 500;
        font-size: 22px;
        line-height: 27px;
        @media (max-width: 992px){
            font-size: 18px;
            line-height: 22px;
        }
        @media (max-width: 768px){
            font-size: 14px;
            line-height: 17px;
        }
    }
}